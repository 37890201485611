import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import Grid from "@mui/material/Grid2";
import MDBox from "components/MDBase/MDBox";
import MiniStatisticsCard from "components/MDComponents/Cards/StatisticsCards/MiniStatisticsCard";
import MDTypography from "components/MDBase/MDTypography";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { downloadChart, formatDate } from "util/UtilHelper";
import { CircularProgress } from "@mui/material";
import { Line, Pie } from "react-chartjs-2";
import {
  temperatureGraphBaseData,
  temperatureGraphOptions,
  temperaturePieBaseData,
  temperaturePieOptions,
} from "./configs";
import { getMeterTemperatureAnalysis } from "util/APIHelper";
import { downloadExcel } from "util/ExcelUtil";

const TemperatureGraph = React.forwardRef(({ endpoint, startDate, endDate }, ref) => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(temperatureGraphBaseData);
  const [pieData, setPieData] = useState(temperaturePieBaseData);
  const [maxTemp, setMaxTemp] = useState(null);
  const [minTemp, setMinTemp] = useState(null);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await getMeterTemperatureAnalysis(formatDate(startDate), formatDate(endDate), endpoint);
      if (response.success) {
        const temperatureGraphData = response.content.map((item)=>{
          return {x:item["timestamp_utc"], y:item["temperature"]}
        });

        let currentGraphData = structuredClone(temperatureGraphBaseData);
        currentGraphData.datasets[0].data = temperatureGraphData;
        setData(currentGraphData)

        if (temperatureGraphData.length>0) {
          setMinTemp(Math.min(...temperatureGraphData.map(obj => obj.y)));
          setMaxTemp(Math.max(...temperatureGraphData.map(obj => obj.y)));

          let currentPieData = structuredClone(temperaturePieBaseData);
          currentPieData.datasets[0].data[0] = ((temperatureGraphData.filter((e) => e.y<20).length/temperatureGraphData.length)*100).toFixed(2)
          currentPieData.datasets[0].data[1] = ((temperatureGraphData.filter((e) => (e.y>=20 && e.y<=30)).length/temperatureGraphData.length)*100).toFixed(2)
          currentPieData.datasets[0].data[2] = ((temperatureGraphData.filter((e) => e.y>30).length/temperatureGraphData.length)*100).toFixed(2)
          setPieData(currentPieData)
        } else {
          setPieData(temperaturePieBaseData);
          setMinTemp(null);
          setMaxTemp(null);
        }
      } else {
        setPieData(temperaturePieBaseData);
        setData(temperatureGraphBaseData);
        setMaxTemp(null);
        setMinTemp(null);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }

  const chartOptions = temperatureGraphOptions
  const pieOptions = temperaturePieOptions
  const chartRef = useRef(null);
  const pieRef = useRef(null);

  const [chartEl, setChartEl] = useState(null);
  const handleChartMenuOpen = (event) => { setChartEl(event.currentTarget); };
  const handleChartMenuClose = () => { setChartEl(null); };

  const [pieEl, setPieEl] = useState(null);
  const handlePieMenuOpen = (event) => { setPieEl(event.currentTarget); };
  const handlePieMenuClose = () => { setPieEl(null); };

  const getTempColor = (value) => {
    if (value === null) {
      return "secondary"
    }
    if (value >30) {
      return "warning"
    } else if (value >20) {
      return "success"
    } else {
      return "info"
    }
  }


  React.useImperativeHandle(ref, () => ({
    getData
  }));
  return (
    <Grid container spacing={1}>
      <Grid size={{xs:12, md: 8, xl:9}} order={{ xs: 2, md: 1 }}>
        <Card>
          <MDBox display="flex" px={2} pt={2}>
            <MDBox mr="auto">
              <MDTypography variant="h6">Temperature</MDTypography>
              <MDBox mb={2}>
                <MDTypography component="div" variant="button" color="text">
                  Analysis Graph
                </MDTypography>
              </MDBox>
            </MDBox>
            <IconButton
              disabled={isLoading}
              color="info"
              onClick={getData}
              sx={{width:"2rem", height:"2rem", marginTop:"0.5rem", marginRight:"0.5rem"}}
            >
              <Icon color="secondary" fontSize="medium">refresh</Icon>
            </IconButton>
            <IconButton
              disabled={isLoading}
              color="info"
              onClick={handleChartMenuOpen}
              sx={{width:"2rem", height:"2rem", marginTop:"0.5rem"}}
            >
              <Icon color="secondary" fontSize="medium">more_vert_icon</Icon>
            </IconButton>
            <Menu
              anchorEl={chartEl}
              open={Boolean(chartEl)}
              onClose={handleChartMenuClose}
            >
              <MenuItem onClick={() => { downloadChart(chartRef, 'Temperature graph'); handleChartMenuClose(); }}>Download Image</MenuItem>
              <MenuItem
                onClick={() => {
                  const { datasets } = data
                  const contents = {
                    "Temperature": datasets[0].data.map((item)=>({"Timestamp":item.x, "Temperature": item.y})),
                  }
                  downloadExcel("Temperature graph data", contents);
                }}
              >
                Download Excel
              </MenuItem>
            </Menu>
          </MDBox>
          {
            isLoading ? (
              <MDBox height="30rem" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                <CircularProgress color="info" />
              </MDBox>
            ):(
              <MDBox height="30rem" px={1} pb={1}>
                <Line ref={chartRef} data={data} options={chartOptions} redraw />
              </MDBox>
            )
          }
        </Card>
      </Grid>
      <Grid size={{xs:12, md: 4, xl:3}} order={{ xs: 1, md: 2 }}>
        <MDBox>
          <Grid container spacing={1}>
            <Grid size={{ xs:12, md:12}}>
              <Card>
                <MDBox display="flex" px={2} pt={2}>
                  <MDBox mr="auto">
                    <MDTypography variant="h6">Temperature Distribution</MDTypography>
                    <MDBox mb={2}>
                      <MDTypography component="div" variant="button" color="text">
                        Pie Chart
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <IconButton
                    disabled={isLoading}
                    color="info"
                    onClick={handlePieMenuOpen}
                    sx={{width:"2rem", height:"2rem", marginTop:"0.5rem"}}
                  >
                    <Icon color="secondary" fontSize="medium">more_vert_icon</Icon>
                  </IconButton>
                  <Menu
                    anchorEl={pieEl}
                    open={Boolean(pieEl)}
                    onClose={handlePieMenuClose}
                  >
                    <MenuItem onClick={() => { downloadChart(pieRef, 'Temperature Distribution chart'); handleChartMenuClose(); }}>Download Image</MenuItem>
                    <MenuItem
                      onClick={() => {
                        const { datasets, labels } = pieData
                        const values = [
                          {"Range": labels[0], "Percentage (%)": datasets[0].data[0]},
                          {"Range": labels[1], "Percentage (%)": datasets[0].data[1]},
                          {"Range": labels[2], "Percentage (%)": datasets[0].data[2]},
                        ]
                        const contents = {
                          "Temperature": values
                        }
                        downloadExcel("Temperature chart data", contents);
                      }}
                    >
                      Download Excel
                    </MenuItem>
                  </Menu>
                </MDBox>
                {
                  isLoading ? (
                    <MDBox height="17rem" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                      <CircularProgress color="info" />
                    </MDBox>
                  ):(
                    <MDBox height="17rem" px={1} pb={1}>
                      <Pie ref={pieRef} data={pieData} options={pieOptions} redraw />
                    </MDBox>
                  )
                }
              </Card>
            </Grid>
            <Grid size={{ xs:6, md:12}}>
              <MiniStatisticsCard
                title={{ text: "Maximum Temp" }}
                count={`${(isLoading || !maxTemp)?'':maxTemp} °C`}
                icon={{ color: getTempColor(maxTemp), component: "thermostat" }}
                direction="left"
              />
            </Grid>
            <Grid size={{ xs:6, md:12}}>
              <MiniStatisticsCard
                title={{ text: "Minimum Temp" }}
                count={`${(isLoading || !minTemp)?'':minTemp} °C`}
                icon={{ color: getTempColor(minTemp), component: "ac_unit" }}
                direction="left"
              />
            </Grid>
          </Grid>
        </MDBox>
      </Grid>
    </Grid>
  )
});

TemperatureGraph.propTypes = {
  endpoint: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired
}

export default TemperatureGraph;