/* eslint-disable react/prop-types */
import { epochToTimestamp } from "util/UtilHelper";
import MeterSerialNumberCell from "components/TableCells/General/MeterSerialNumberCell";
import DownlinkStatusCell from "components/TableCells/DownlinkTable/DownlinkStatusCell";
import DownlinkParameterCell from "components/TableCells/DownlinkTable/DownlinkParameterCell";
import { getDownlinkStatusText } from "util/LookupUtil";

export const columnDefs = [
  { header: "Meter S/N", accessorKey: "meter_sn",
    Cell: ({_, row}) =>
      <MeterSerialNumberCell
        meterSerialNumber={row.original.meter_sn}
        endpoint={row.original.endpoint}
      />
  },
  { header: "Command", accessorKey: "command" },
  { header: "Parameters", accessorKey: "parameters",
    Cell: ({ cell }) => <DownlinkParameterCell parameters={cell.getValue()} />
  },
  { header: "Status", accessorKey: "status",
    Cell: ({ cell }) => <DownlinkStatusCell status={cell.getValue()} /> },
  { header: "Created Timestamp", accessorKey: "created_timestamp" },
  { header: "Completed Timestamp", accessorKey: "completed_timestamp"}
]

export const downlinkTableProcess = (rawData) => {
  return rawData.map((singular) => {
    return {
      item: singular,
      endpoint: singular.endpoint,
      meter_sn: (singular.meterSerialNumber) ? singular.meterSerialNumber : singular.endpoint,
      command: singular.commandName,
      parameters: singular.parameters.reduce((accumulator, param) =>
        accumulator + `${param.description}: ${param.value}\n`, ''
      ),
      created_timestamp: epochToTimestamp(singular.createdUtc),
      completed_timestamp: epochToTimestamp(singular.modifiedUtc),
      status: getDownlinkStatusText(singular.requestStatusDesc)
    }

  });
}