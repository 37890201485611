import React, { useEffect, useState } from "react";
import DashboardLayout from "components/MDComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/MDComponents/Navbars/DashboardNavbar";
import { useMaterialUIController } from "context/md";
import Card from "@mui/material/Card";
import ChangePasswordSection from "./ChangePasswordSection";
import { setSBContent, setSBOpen, useSBController } from "../../context/snackbar";
import MDBox from "components/MDBase/MDBox";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDBase/MDTypography";
import AccessLevelCell from "components/TableCells/UserTable/AccessLevelCell";
import { putUpdateDatetime } from "util/APIHelper";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "components/MDBase/MDInput";
import PreferenceSection from "./PreferenceSection";
import Grid from "@mui/material/Grid2";
import { FixedSizeList as List } from "react-window";
import { allSites } from "signals/group_signals";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import ListItem from "@mui/material/ListItem";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";

function Profile() {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [datetime, setDatetime] = useState("");
  const [controller] = useMaterialUIController();
  const { user } = controller;
  const [sb_controller, sb_dispatch] = useSBController();
  const {content} = sb_controller;


  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setRole(user.role);
      setDatetime(user.datetimeFormat);
    }
  }, [user]);

  const handleSB = (newState) => {
    setSBContent(sb_dispatch, {
      ...content,
      ...newState
    })
    setSBOpen(sb_dispatch,true)
  }

  const container = (item) =>
    <MDBox
      borderRadius="lg"
      display="flex"
      alignItems="center"
      sx={{
        padding: "0.5rem 1rem 0.5rem 1rem",
        border: ({ borders: { borderWidth, borderColor } }) =>
          `${borderWidth[1]} solid ${borderColor}`,
      }}
    >
      {item}
    </MDBox>

  const renderEmail = () => {
    return container(
      <>
        <Icon fontSize="medium" color="secondary" sx={{marginRight: "1rem"}}>email</Icon>
        <MDTypography variant="body2">{email}</MDTypography>
      </>,
      "Email"
    );
  }

  const renderRole = () => {
    return container(
      <>
        <Icon fontSize="medium" color="secondary" sx={{marginRight: "0.5rem"}}>badge</Icon>
        <AccessLevelCell access={role}/>
      </>,
      "User Role"
    );
  }

  const handleDatetimeChange = async (value) => {
    setDatetime(value);
    try {
      const response = await putUpdateDatetime(value, user.id);
      handleSB({
        color: response.success ? "success" : "error",
        icon: response.success ? "check" : "warning",
        title: response.success ? "Success" : "Error",
        content: response.success ? response.content : response.message
      });
    } catch (e) {
      console.error(e)
    }
  }

  const renderDatetime = () => {
    return container(
      <>
        <Icon fontSize="medium" color="secondary" sx={{marginRight: "1rem"}}>today</Icon>
        <Autocomplete
          options={["DD/MM/YYYY", "MM/DD/YYYY", "DD-MM-YYYY", "MM-DD-YYYY"]}
          value={datetime}
          onChange={(event, value) => handleDatetimeChange(value)}
          renderInput={(params) => <MDInput variant="standard" {...params} />}
          size="small"
          disableClearable
          fullWidth
        />
      </>,
      "Preferred Datetime"
    );
  }

  const [tabValue, setTabValue] = useState(0);
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const Row = ({ index, style, data }) => {
    let item = data[index];
    if (item.id === -1) return null;

    return (
      <ListItem
        key={uuidv4()}
        style={style}
        sx={{ borderBottom: "1px solid lightgray" }}
      >
        <Grid container>
          <Grid size={{xs:12}}>
            <MDTypography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                marginTop: "1rem"
              }}
              fontWeight="bold"
              verticalAlign="bottom"
              variant="body2"
              color="text"
            >
              {item.description}
            </MDTypography>
          </Grid>

          <Grid ize={{xs:12}}>
            <MDTypography verticalAlign="top" variant="caption" color="text">
              {item.groupDescription}
            </MDTypography>
          </Grid>
        </Grid>
      </ListItem>
    );
  };

  Row.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
    data: PropTypes.any.isRequired
  };

  return (
    <DashboardLayout>
      <DashboardNavbar hideSites />
      <Grid container spacing={2}>
        <Grid size={{xs:12, md:6}}>
          <MDBox>
            <Grid container spacing={2}>
              <Grid size={{xs:12}}>
                <Card>
                  <MDBox sx={{display:"flex", flexDirection:"column", padding: "1.5rem", gap:"1rem"}}>
                    <MDTypography variant="h4">Basic Info</MDTypography>
                    {renderEmail()}
                    {renderRole()}
                    {renderDatetime()}
                  </MDBox>
                </Card>
              </Grid>
              <Grid size={{xs:12}}>
                <Card>
                  <MDBox sx={{display:"flex", flexDirection:"column", padding: "1.5rem", gap:"1rem"}}>
                    <MDTypography variant="h4">Site Access</MDTypography>
                    <MDBox sx={{ maxHeight: '350px', overflowY: 'auto' }}>
                      <List
                        height={200}
                        width="100%"
                        itemSize={50}
                        itemCount={allSites.value.filter((item)=>item.id !== -1).length}
                        itemData={allSites.value}
                      >
                        {Row}
                      </List>
                    </MDBox>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
        <Grid size={{xs:12, md:6}}>
          <Card>
            <ChangePasswordSection email={email} handleSB={handleSB} />
          </Card>
        </Grid>
        <Grid size={{xs:12}}>
          <Card>
            <MDBox sx={{display:"flex", flexDirection:"column", padding: "1.5rem", gap:"1rem"}}>
              <MDTypography variant="h4">Preference</MDTypography>
              <AppBar position="static">
                <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
                  <Tab label="Map" />
                  <Tab label="Tables" />
                  <Tab label="Group & Sites" />
                  <Tab label="Alarms" />
                </Tabs>
              </AppBar>
              <PreferenceSection handleSB={handleSB} email={email} tab={tabValue}/>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default Profile;
