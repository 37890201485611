/* eslint-disable react/prop-types */
import ActiveCell from "components/TableCells/General/ActiveCell";
import AccessLevelCell from "components/TableCells/UserTable/AccessLevelCell";
import React from "react";
import { epochToTimestamp } from "../../util/UtilHelper";

export const columnDefs = [
  { header: "Email", accessorKey: "email",
    muiEditTextFieldProps: {
      type: 'email',
      required: true,
    },
  },
  { header: "Access Level", accessorKey: "access_level",
    Cell: ({ cell }) => <AccessLevelCell access={cell.getValue()} />,
    filterVariant: "select",
    filterSelectOptions: [
      { text: "Viewer", value: "viewer" },
      { text: "Admin", value: "admin" },
      { text: "Superuser", value: "superuser" },
    ],
  },
  { header: "Date Format", accessorKey: "date_format", },
  { header: "Active", accessorKey: "active",
    Cell: ({ cell }) => <ActiveCell active={cell.getValue()} />,
    filterVariant: "select",
    filterSelectOptions: [
      { text: "Active", value: "active" },
      { text: "Inactive", value: "inactive" },
    ],
  },
  { header: "Created", accessorKey: "created",
    // todo try using  accessorFn: (originalRow) => epochToTimestamp(originalRow.timestampUtc),
  },
  { header: "Modified", accessorKey: "modified",  },
];

export const userTableProcess = (rawData) => {
  return rawData.map((singular) => {
    return {
      item: singular,
      email: singular.email,
      access_level: singular.roleDescription,
      date_format: singular.dateFormat,
      active: (singular.active)?"active":"inactive",
      created: epochToTimestamp(singular.createdUtc),
      modified: epochToTimestamp(singular.modifiedUtc)
    }
  });
}