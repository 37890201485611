import PropTypes from "prop-types";
import StatisticsCard from "components/Cards/StatisticsCard";
import {epochToTimestamp} from "util/UtilHelper";

function LatestReadingCard({reading, timestamp, loading}) {

  return (
    <StatisticsCard
      color="info"
      icon="gas_meter"
      title="Latest Reading"
      count={`${(reading/1000).toFixed(4)} m³`}
      percentage={{
        label: (timestamp!==0)?epochToTimestamp(timestamp):"",
      }}
      isLoading={loading}
    />
  );
}

LatestReadingCard.defaultProps = {
  loading: false
}

LatestReadingCard.propTypes = {
  reading: PropTypes.number.isRequired,
  timestamp: PropTypes.number.isRequired,
  loading: PropTypes.bool
}

export default LatestReadingCard;