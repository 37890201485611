import PropTypes from "prop-types";
import StatisticsCard from "components/Cards/StatisticsCard";
import {getFormattedDateRange} from "util/UtilHelper";

function PastConsumptionCard({consumption, loading}) {

  return (
    <StatisticsCard
      color="info"
      icon="water_drop"
      title="30 Days Consumption"
      count={`${(consumption/1000).toFixed(4)} m³`}
      percentage={{
        label: getFormattedDateRange(),
      }}
      isLoading={loading}
    />
  );
}

PastConsumptionCard.defaultProps = {
  loading: false
}

PastConsumptionCard.propTypes = {
  consumption: PropTypes.number.isRequired,
  loading: PropTypes.bool
}

export default PastConsumptionCard;