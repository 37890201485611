import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid2";
import MDDatePicker from "components/MDBase/MDDatePicker";
import MDTypography from "components/MDBase/MDTypography";
import MDBox from "components/MDBase/MDBox";
import MDButton from "components/MDBase/MDButton";
import Icon from "@mui/material/Icon";
import BatteryGraph from "./components/batteryGraph";
import TemperatureGraph from "./components/temperatureGraph";
import TrendLog from "./components/trendLog";
import ReadingConsumptionGraph from "./components/readingConsumptionGraph";
import AlarmCountTimeline from "./components/alarmCountTimeline";
import SignalGraph from "./components/signalGraph";
import Tooltip from "@mui/material/Tooltip";
import { Info } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";

function DetailsAnalysis({endpoint}) {
  const batteryComponentRef = useRef();
  const temperatureComponentRef = useRef();
  const readingComponentRef = useRef();
  const signalComponentRef = useRef();
  const alarmComponentRef = useRef();

  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date());
  const handleDateChange = (date) => {
    if (date.length===2) {
      setStartDate(date[0]);
      setEndDate(date[1]);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const [isLoading, setLoading] = useState(false);
  const checkDisable = () => {
    return !!((startDate && endDate && endDate < startDate) ||
      !startDate ||
      !endDate ||
      isLoading);
  }

  const handleSubmit = async () => {
    if (batteryComponentRef.current) batteryComponentRef.current.getData();
    if (temperatureComponentRef.current) temperatureComponentRef.current.getData();
    if (readingComponentRef.current) readingComponentRef.current.getData();
    if (signalComponentRef.current) signalComponentRef.current.getData();
    if (alarmComponentRef.current) alarmComponentRef.current.getData();
  }

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid size={{xs:12}}>
        <MDBox display="flex" alignItems="center">
          <MDDatePicker
            input={{
              sx:{width:"15rem"},
              slotProps:{
                input: {
                  endAdornment: <InputAdornment position="end">
                    <Tooltip title={
                      <MDTypography variant="button" color="white">
                        Large datasets can take
                        <b style={{color:"orangered"}}> longer to load </b>
                        and might <b style={{color:"orangered"}}>slow down</b> performance.
                      </MDTypography>}>
                      <Info fontSize="small" color={
                        (new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24) > 30 ? "warning" : "secondary"
                      }/>
                    </Tooltip>
                  </InputAdornment>,
                },
              }
            }}
            onChange={handleDateChange}
            placeholder="Date Range"
            range
            options={{
              mode: "range",
              dateFormat: "Y-m-d",
              maxDate: new Date(),
              defaultDate: [
                new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                new Date()
              ]
            }}
          />
          <MDBox ml={2}>
            <MDButton disabled={checkDisable()} onClick={handleSubmit} variant="outlined" color="info">
              <Icon>refresh</Icon>&nbsp; Refresh
            </MDButton>
          </MDBox>
        </MDBox>
      </Grid>
      <Grid size={{xs:12}}>
        <BatteryGraph
          ref={batteryComponentRef}
          endpoint={endpoint}
          startDate={startDate}
          endDate={endDate}
        />
      </Grid>
      <Grid size={{xs:12}}>
        <TemperatureGraph
          ref={temperatureComponentRef}
          endpoint={endpoint}
          startDate={startDate}
          endDate={endDate}
        />
      </Grid>
      <Grid size={{xs:12}}>
        <ReadingConsumptionGraph
          ref={readingComponentRef}
          endpoint={endpoint}
          startDate={startDate}
          endDate={endDate}
        />
      </Grid>
      <Grid size={{xs:12}}>
        <SignalGraph
          ref={signalComponentRef}
          endpoint={endpoint}
          startDate={startDate}
          endDate={endDate}
        />
      </Grid>
      <Grid size={{xs:12}}>
        <AlarmCountTimeline
          ref={alarmComponentRef}
          endpoint={endpoint}
          startDate={startDate}
          endDate={endDate}
        />
      </Grid>
      <Grid size={{xs:12}}>
        {/*<TrendLog*/}
        {/*  endpoint={endpoint}*/}
        {/*  startDate={startDate}*/}
        {/*  endDate={endDate}*/}
        {/*/>*/}
      </Grid>
    </Grid>
  );
}

DetailsAnalysis.propTypes = {
  endpoint: PropTypes.string.isRequired,
}
export default DetailsAnalysis;
