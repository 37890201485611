import DashboardNavbar from "components/MDComponents/Navbars/DashboardNavbar";
import DashboardLayout from "components/MDComponents/LayoutContainers/DashboardLayout";
import React from "react";

function AnalysisTrendLogs() {
  return (
    <DashboardLayout>
      <DashboardNavbar/>
    </DashboardLayout>
  );
}

export default AnalysisTrendLogs;