import PropTypes from "prop-types";
import StatisticsCard from "components/Cards/StatisticsCard";
import {epochToTimestamp} from "util/UtilHelper";

function TemperatureCard({temperature, timestamp, loading}) {

  return (
    <StatisticsCard
      color="info"
      icon="device_thermostat"
      title="Temperature"
      count={`${temperature} °C`}
      percentage={{
        label: (timestamp!==0)?epochToTimestamp(timestamp):"",
      }}
      isLoading={loading}
    />
  );
}

TemperatureCard.defaultProps = {
  loading: false
}

TemperatureCard.propTypes = {
  temperature: PropTypes.number.isRequired,
  timestamp: PropTypes.number.isRequired,
  loading: PropTypes.bool
}

export default TemperatureCard;