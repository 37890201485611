import React, { useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import DashboardLayout from "components/MDComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/MDComponents/Navbars/DashboardNavbar";
import MRTable from "../../../components/MaterialReactTable";
import { getUsers } from "../../../util/APIHelper";
import {userTableProcess } from "../../users/tableUtil";
import AccessLevelCell from "../../../components/TableCells/UserTable/AccessLevelCell";
import ActiveCell from "../../../components/TableCells/General/ActiveCell";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ShowHideColumnsButton, MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton, MRT_ToggleRowActionMenuButton,
  useMaterialReactTable,
} from "material-react-table";
import MDBox from "../../../components/MDBase/MDBox";
import {
  muiBottomToolbarProps,
  muiFilterTextFieldProps, muiLinearProgressProps, muiPaginationProps, muiSearchTextFieldProps, muiTableHeadProps,
  muiTablePaperProps,
  muiTopToolbarProps,
} from "../../../components/MaterialReactTable/style";
import { InfoOutlined, SearchOutlined } from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import MDTypography from "../../../components/MDBase/MDTypography";
import AnimatedLoadingIconButton from "../../../components/MaterialReactTable/AnimatedLoadingIconButton";
import DownloadExcelButton from "../../../components/MaterialReactTable/DownloadExcelButton";
import MDButton from "../../../components/MDBase/MDButton";

function MaterialTableTest() {
  const [isLoading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(()=>{
    getData();
  }, []);

  const getData = async (vis=null) => {
    setLoading(true)
    try {
      const response = await getUsers()
      setUsers(userTableProcess(response.content));

    } catch (e) {
      console.error(e)
    }
    setLoading(false)
  }

  // Column definitions
  const columns = [
    { header: "name", accessorKey: "name", },
    { header: "age", accessorKey: "age", },
  ];

  const data = [
    {
      name: "John",
      age: 30,},
    {
      name: "John",
      age: 30,},
    {
      name: "Jane",
      age: 25,},
    {
      name: "Bob",
      age: 40,},
    {
      name: "Alice",
      age: 35,},
    {
      name: "Charlie",
      age: 28,},
    {
      name: "David",
      age: 32,},
  ]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MRTable data={data} columns={columns} enableRowSelection={true}
                   customTopLeftToolbar={(table)=>(
                     <MDButton onClick={()=>{
                       console.log(table.getSelectedRowModel().rows);
                     }}>test</MDButton>
                   )}
                   onRowSelectionChange={(table)=>{
                     console.log(table.getSelectedRowModel().rows.map(it => it.original));
                   }}
          />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default MaterialTableTest;
