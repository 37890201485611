/* eslint-disable react/prop-types */
import { epochToTimestamp } from "util/UtilHelper";

export const columnDefs = [
  { header: "Timestamp", accessorKey: "timestamp" },
  { header: "Transmissions", accessorKey: "transmissions" },
  { header: "Failed Transmissions", accessorKey: "failed_transmissions" },
  { header: "Latency (ms)", accessorKey: "latency" },
  { header: "Ping Latency (ms)", accessorKey: "ping_latency" },
  { header: "RSRP (dBm)", accessorKey: "rsrp",
    filterVariant: 'range-slider',
    muiFilterSliderProps: {
      max: -44,
      min: -140,
      step: 1
    },},
  { header: "RSSI (dBm)", accessorKey: "rssi",
    filterVariant: 'range-slider',
    muiFilterSliderProps: {
      max: -30,
      min: -120,
      step: 1
    },},
  { header: "SINR (dB)", accessorKey: "sinr",
    filterVariant: 'range-slider',
    muiFilterSliderProps: {
      max: 30,
      min: -20,
      step: 1
    },},
  { header: "RSRQ (dB)", accessorKey: "rsrq",
    filterVariant: 'range-slider',
    muiFilterSliderProps: {
      max: -3,
      min: -20,
      step: 1
    },},
  { header: "Tx Power (dBm)", accessorKey: "tx_power" },
  { header: "CE Mode", accessorKey: "ce_mode", hide: true },
  { header: "ECL", accessorKey: "ecl", hide: true },
  { header: "Battery Voltage (V)", accessorKey: "battery_voltage" },
]

export const detailsStatusTableProcess = (rawData) => {
  return rawData.map((singular) => {
    return {
      item: singular,
      timestamp: epochToTimestamp(singular.timestampUtc),
      transmissions: singular.noOfTransmission,
      failed_transmissions: singular.noOfFailedTransmission,
      latency: singular.latency_ms,
      ping_latency: singular.pingLatency_ms,
      rsrp: singular.rsrp,
      rssi: singular.rssi,
      sinr: singular.sinr,
      rsrq: singular.rsrq,
      tx_power: singular.txPower,
      ce_mode: singular.ceMode,
      ecl: singular.ecl,
      battery_voltage: singular.batteryVoltage_mV / 1000
    }

  });
}