import React, { useEffect, useMemo, useState } from "react";
import {Card} from "@mui/material";
import PropTypes from "prop-types";
import VerticalBarChart from "components/MDComponents/Charts/BarCharts/VerticalBarChart";
import MDBox from "components/MDBase/MDBox";
import MDButton from "components/MDBase/MDButton";
import MDInput from "components/MDBase/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import { checkStatus } from "../../../../../util/UtilHelper";

const GroupsLogAnomalyGraph = ({selectedMeters, endpointIntervals, data, groups, isLoading}) => {
  const [chartData, setChartData] = useState({});
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [stacked, setStacked] = useState(true);
  const [isProcessing, setProcessing] = useState(false);

  useEffect(() => {
    if (!isLoading && data.length>0) {
      if (groups.length>0) {
        const groupList = groups.slice(0,10)
        setSelectedGroups(groupList)
        processData(groupList);
      }
    }
  }, [isLoading]);

  const processData = (items = null) => {
    let colors = {};
    try {
      if (JSON.parse(sessionStorage.getItem("group_site_color"))) {
        colors = JSON.parse(sessionStorage.getItem("group_site_color"));
      }
    } catch (e) {
      console.error(e)
    }
    setProcessing(true);
    const datasets = []

    groups.forEach((group) => {
      if (((items)??selectedGroups).find((obj) => obj.id === group.id)) {
        const list = selectedMeters.filter((meter) => meter.group_id === group.id ).map((_)=>_.endpoint)
        const filteredData = data.map((dates) => {
          return {
            ...dates,
            data: dates.data.filter((date_data) => (list.includes(date_data.endpoint)))
          }
        });
        const filteredEndpointIntervals = endpointIntervals.filter((intervals) => list.includes(intervals.endpoint))
        const data_list = []

        filteredData.forEach((item)=> {
          let i = 0
          item.data.forEach((item2) => {
            if (item2.count<(filteredEndpointIntervals.find((_)=>_.endpoint === item2.endpoint).expectedLogCount)) {
              i++;
            }
          });
          data_list.push(i)
        });

        let c = (data_list.reduce((acc, cur) => acc + cur, 0)>0)?"warning":"info";

        if (colors.groups) {
          if (colors.groups.length > 0) {
            const index = colors.groups.findIndex((obj) => obj.id === group.id);
            if (index > -1) {
              c = colors.groups[index].color
            }
          }
        }

        const subData = {
          label: group.name,
          color: c,
          data: data_list
        }

        datasets.push(subData)
      }
    });

    const processedData = {
      labels: data.map((item) => item.date),
      datasets: datasets
    }
    setChartData(processedData)
  }

  const onChange = (item) => {
    setSelectedGroups(item)
    processData(item);
  }

  return (
    <Card>
      {
        useMemo(()=>(
          <VerticalBarChart
            icon={{
              color:"info",
              component: "leaderboard"
            }}
            title="Groups Log Anomaly"
            height="30rem"
            description=" "
            chart={chartData}
            loading={isLoading}
            stacked={stacked}
            legend
            extraAction={
              <>
                <MDBox sx={{ marginRight: "1rem", marginTop:"-0.375rem"}}>
                  <MDButton
                    color="secondary"
                    size="small"
                    variant="outlined"
                    onClick={()=>{setStacked(!stacked)}}
                  >
                    {stacked?"Stacked":"Grouped"}
                  </MDButton>
                </MDBox>

                <Autocomplete
                  sx={{ paddingLeft: "1rem", minWidth: "11rem", marginRight:"1rem", marginBottom:"1rem", maxWidth:"20rem"}}
                  multiple
                  limitTags={1}
                  value={selectedGroups}
                  options={groups}
                  onChange={(event, value) => {
                    onChange(value);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <MDInput  label="Groups" {...params} />}
                />
              </>
            }
          />
        ), [chartData, isLoading, selectedGroups, isProcessing, stacked])
      }
    </Card>
  );
}

GroupsLogAnomalyGraph.defaultProps = {
  isLoading: false
}

GroupsLogAnomalyGraph.propTypes = {
  selectedMeters: PropTypes.array.isRequired,
  endpointIntervals: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  isLoading: PropTypes.bool

}

export default GroupsLogAnomalyGraph;