/* eslint-disable react/prop-types */
import { epochToTimestamp } from "util/UtilHelper";
import AnomalyMessageCell from "components/TableCells/AnomalyTable/AnomalyMessageCell";

export const columnDefs = [
  { header: "Timestamp", accessorKey: "timestamp", },
  { header: "Anomaly Function", accessorKey: "anomaly_function", },
  { header: "Anomaly Message", accessorKey: "anomaly_message", size: 1000,
    Cell: ({ _, row }) => <AnomalyMessageCell data={row.original} />, },
]

export const detailsAnomalyTableProcess = (rawData) => {
  return rawData.map((singular) => {
    return {
      anomaly_function: singular.anomalyFunction,
      anomaly_message: singular.anomalyMessage,
      timestamp: epochToTimestamp(singular.createdUtc),
    }
  });
}