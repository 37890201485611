/** 
  All of the routes for the MD React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  11. The `component` key is used to store the component of its route.
  12. noCollapse: true for singular route
*/

// @mui icons
import Icon from "@mui/material/Icon";

import Dashboard from "layouts/dashboard";
import Meter from "layouts/meter";
import MeterDetails from "layouts/meter/details";
import MeterAnomaly from "layouts/meter/anomaly";
import Login from "layouts/auth/login";
import Logout from "layouts/auth/logout";
import ServerTableTest from "layouts/test/serverTable";
import Downlink from "layouts/downlink";
import Firmware from "layouts/firmware";
import Users from "layouts/users";
import FirmwareFiles from "layouts/firmware/Files";
import AnalysisKPI from "layouts/analysis/kpi";
import AnalysisAlarm from "layouts/analysis/alarm";
import AnalysisConsumption from "layouts/analysis/consumption";
import ChartsTest from "layouts/test/charts";
import DownlinkQueue from "layouts/downlink/Queue";
import FirmwareQueue from "layouts/firmware/Queue";
import ForgotPassword from "layouts/auth/forgotPassword";
import MeshNetworkVisualizer from "./layouts/visualizer";
import MaterialTableTest from "./layouts/test/materialtable";
import Profile from "./layouts/profile";
import Groups from "./layouts/groups";
import Sites from "./layouts/sites";
import AnalysisTrendLogs from "./layouts/analysis/trendLogs";

const routes = [
  {
    hidden: true,
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "/profile",
    noCollapse: true,
    icon: <Icon fontSize="medium">account_circle</Icon>,
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "",
    route: "/",
    noCollapse: true,
    icon: <Icon fontSize="medium">dashboard</Icon>,
    component: <Dashboard />,
  },

  {
    hidden: true,
    type: "collapse",
    name: "Tests",
    key: "test",
    icon: <Icon color="warning" fontSize="medium">bug_report</Icon>,
    collapse: [
      {
        name: "MR Table",
        key: "mr-table",
        route: "/test/mr-table",
        component: <MaterialTableTest />,
      },
      {
        name: "Server Table",
        key: "server-table",
        route: "/test/server-table",
        component: <ServerTableTest />,
      },
      {
        name: "Charts",
        key: "charts",
        route: "/test/charts",
        component: <ChartsTest />,
      },
    ]
  },
  {
    login: true,
    hidden: true,
    type: "collapse",
    name: "Login",
    key: "login",
    route: "/login",
    noCollapse: true,
    icon: <Icon fontSize="medium">login</Icon>,
    component: <Login />,
  },
  {
    login: true,
    hidden: true,
    type: "collapse",
    name: "Forgot Password",
    key: "forgotPassword",
    route: "/forgotPassword",
    noCollapse: true,
    icon: <Icon fontSize="medium">login</Icon>,
    component: <ForgotPassword />,
  },
  {
    hidden: true,
    logout: true,
    type: "collapse",
    name: "Logout",
    key: "logout",
    route: "/logout",
    noCollapse: true,
    icon: <Icon fontSize="medium">logout</Icon>,
    component: <Logout />,
  },

  {
    type: "collapse",
    name: "Meter",
    key: "meter",
    icon: <Icon fontSize="medium">gas_meter</Icon>,
    collapse: [
      {
        name: "Status",
        key: "meter",
        route: "/meter",
        component: <Meter />,
      },
      {
        hidden: true,
        key: "meter",
        route: "/meter/details",
        component: <MeterDetails />,
      },
      {
        name: "Anomaly",
        key: "anomaly",
        route: "/meter/anomaly",
        component: <MeterAnomaly />,
      },
    ],
  },

  {
    type: "collapse",
    name: "Groups",
    key: "groups",
    route: "/groups",
    noCollapse: true,
    icon: <Icon fontSize="medium">apartment</Icon>,
    component: <Groups />
  },

  {
    hidden: true,
    type: "collapse",
    name: "Sites",
    key: "sites",
    route: "/sites",
    noCollapse: true,
    icon: <Icon fontSize="medium">apartment</Icon>,
    component: <Sites />
  },

  {
    type: "collapse",
    name: "Analysis",
    key: "analysis",
    icon: <Icon fontSize="medium">analytics</Icon>,
    collapse: [
      {
        name: "Consumption",
        key: "consumption",
        route: "/analysis/consumption",
        component: <AnalysisConsumption />,
      },
      {
        name: "Alarm",
        key: "alarm",
        route: "/analysis/alarm",
        component: <AnalysisAlarm />,
      },
      {
        name: "KPI",
        key: "kpi",
        route: "/analysis/kpi",
        component: <AnalysisKPI />,
      },
      // {
      //   name: "Trend Logs",
      //   key: "trendLogs",
      //   route: "/analysis/trendlogs",
      //   component: <AnalysisTrendLogs />,
      // },
    ],
  },

  {
    access: 20,
    type: "collapse",
    name: "Downlink",
    key: "downlink",
    icon: <Icon fontSize="medium">cloud_download</Icon>,
    collapse: [
      {
        access: 20,
        name: "Status",
        key: "downlink",
        route: "/downlink",
        component: <Downlink />,
      },
      {
        access: 30,
        name: "Queue",
        key: "queue",
        route: "/downlink/queue",
        component: <DownlinkQueue />,
      },
    ],
  },

  {
    access: 20,
    type: "collapse",
    name: "Firmware",
    key: "firmware",
    icon: <Icon fontSize="medium">memory</Icon>,
    collapse: [
      {
        access: 20,
        name: "Status",
        key: "firmware",
        route: "/firmware",
        component: <Firmware />,
      },
      {
        access: 30,
        name: "Queue",
        key: "queue",
        route: "/firmware/queue",
        component: <FirmwareQueue />,
      },
      {
        access: 30,
        name: "Files",
        key: "files",
        route: "/firmware/files",
        component: <FirmwareFiles />,
      },
    ],
  },

  {
    access: 20,
    type: "collapse",
    name: "Users",
    key: "users",
    route: "/users",
    noCollapse: true,
    icon: <Icon fontSize="medium">group</Icon>,
    component: <Users />
  },

  {
    access: 20,
    type: "collapse",
    name: "Mesh Network Visualizer",
    key: "visualizer",
    route: "/visualizer",
    noCollapse: true,
    icon: <Icon fontSize="medium">hub</Icon>,
    component: <MeshNetworkVisualizer />
  },

];

export default routes;
