import {useEffect, useState} from "react";
import {getAlarmAnalysisGraph} from "util/APIHelper";
import PropTypes from "prop-types";
import {Card} from "@mui/material";
import {getLastItem} from "util/UtilHelper";
import VerticalBarChart from "components/MDComponents/Charts/BarCharts/VerticalBarChart";
import { useSignalEffect } from "@preact/signals-react";
import { selectedSites } from "signals/group_signals";
import { getAlarmColor, shortToLong } from "components/AlarmIcon";

function AlarmAnalysisBarChart({timeframe}) {
  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData()
  }, []);

  const [isMounted, setIsMounted] = useState(false);
  useSignalEffect(()=>{
    selectedSites.value
    if (isMounted) {
      getData()
    }
  });

  const getData = async () => {
    setLoading(true);
    try {
      if (selectedSites.value.length>0) {
        const site = selectedSites.value.map(e => e.id)
        const response = await getAlarmAnalysisGraph(timeframe, site.join(","), site.includes(-1));
        processData(response.content)
      } else {
        setData({
          labels: [],
          datasets: [],
        })
      }
    } catch (e) {
      console.error(e)
    }
    setLoading(false);
    if (!isMounted) {
      setIsMounted(true)
    }
  }

  const processData = (data) => {
    let colors = {};
    try {
      if (JSON.parse(sessionStorage.getItem("alarm_color"))) {
        colors = JSON.parse(sessionStorage.getItem("alarm_color"));
      }
    } catch (e) {
      console.error(e)
    }
    const alarms = []

    Object.entries(data).forEach(([key, value]) => {
      if (key !== 'time') {
        if (value.reduce((acc, curr) => acc + curr, 0)>0) {
          let title
          const count = value;

          switch (key) {
            case "backflow": title = "Back Flow"; break;
            case "battery_low": title = "Battery Low"; break;
            case "burst": title = "Burst"; break;
            case "faulty_sensor": title = "Faulty Sensor"; break;
            case "leak": title = "Leak"; break;
            case "magnet": title = "Magnet Tamper"; break;
            case "no_flow": title = "No Consumption"; break;
            case "non_realtime_backflow": title = "Non-Realtime Back Flow"; break;
            case "reboot": title = "Reboot"; break;
            case "tilt": title = "Tilt"; break;
            case "wire_cut": title = "Wire Cut"; break;
            default: title = ""; break;
          }

          alarms.push({
            color: colors[shortToLong(key)]??getAlarmColor(key),
            label: title,
            data: count
          })
        }
      }
    });

    const processedData = {
      labels: data.time,
      x_axis: data.time.map(item => {
        switch (timeframe) {
          case "day": return item.split(" ")[1];
          case "month": return item.slice(-5);
          default: return item
        }
      }),
      datasets: alarms,
    }

    setData(processedData)
  };

  const getIcon = () => {
    switch (timeframe) {
      case "day": return "today";
      case "week": return "date_range";
      case "month": return "calendar_month";
      default: return "water_drop";
    }
  }

  const getTitle = () => `Past ${timeframe} alarms`;

  const getDescription = (from, to) => `${from} - ${to}`;

  return (
    <Card sx={{marginTop: "1rem"}}>
      <VerticalBarChart
        icon={{
          color: "secondary",
          component: getIcon()
        }}
        title={getTitle()}
        height="20rem"
        description={getDescription((data.labels.length>0)?data.labels[0]:'', (data.labels.length>0)?getLastItem(data.labels):'')}
        chart={data}
        loading={loading}
        stacked
      />
    </Card>
  );
}

AlarmAnalysisBarChart.propTypes = {
  timeframe: PropTypes.oneOf(["day", "week", "month"])
}

export default AlarmAnalysisBarChart;