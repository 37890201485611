/* eslint-disable react/prop-types */
import MeterCoordinateCell from "components/TableCells/MeterTable/MeterCoordinateCell";
import MeterSignalCell from "components/TableCells/MeterTable/MeterSignalCell";
import MeterSerialNumberCell from "components/TableCells/General/MeterSerialNumberCell";
import MDBadge from "components/MDBase/MDBadge";

export const columnDefs = [
  { header: "Date", accessorKey: "date" },
  { header: "Endpoint", accessorKey: "endpoint", hide: true },
  { header: "Meter S/N", accessorKey: "meter_sn",
    Cell: ({_, row}) =>
      <MeterSerialNumberCell
        meterSerialNumber={row.original.meter_sn}
        endpoint={row.original.endpoint}
        unClickable={row.original.un_clickable}
      />
  },
  { header: "MIU S/N", accessorKey: "miu_sn", hide: true },
  { header: "Coordinate", accessorKey: "coordinate",
    Cell: ({ _, row }) =>
      <MeterCoordinateCell data={{
        latitude: row.original.latitude,
        longitude: row.original.longitude
      }} />
  },
  { header: "Log Count", accessorKey: "log_count",
    Cell: ({cell}) =>
      <MDBadge variant="contained" color="error" badgeContent={cell.getValue()} container />
  },
  { header: "Group", accessorKey: "group_name" },
  { header: "Site", accessorKey: "site_name" },
  { header: "Custom 1", accessorKey: "custom_1" },
  { header: "Custom 2", accessorKey: "custom_2" },
  { header: "Custom 3", accessorKey: "custom_3" },
  { header: "Custom 4", accessorKey: "custom_4" },
  { header: "Custom 5", accessorKey: "custom_5" },
  { header: "Reading", accessorKey: "reading" },
  { header: "Timestamp", accessorKey: "timestamp" },
  { header: "Signal", accessorKey: "signal",
    Cell: ({ _, row }) => <MeterSignalCell data={row.original} />, },
]