/* eslint-disable react/prop-types */
import { epochToTimestamp } from "util/UtilHelper";
import MDTypography from "components/MDBase/MDTypography";

export const columnDefs = [
  { header: "Timestamp", accessorKey: "timestamp", },
  { header: "Reading (m³)", accessorKey: "reading", },
  { header: "Consumption (m³)", accessorKey: "consumption",
    Cell: ({ cell }) =>
      <MDTypography
        color={(cell.getValue()<0)?"error":"text"}
        variant="body"
      >
        {cell.getValue()}
      </MDTypography>
  },
  { header: "Temperature °C", accessorKey: "temperature", },
  { header: "Battery (%)", accessorKey: "battery",
    filterVariant: 'range-slider',
    muiFilterSliderProps: {
      max: 100,
      min: 0,
      step: 1
    },},
]

export const detailsReadingTableProcess = (rawData) => {
  return rawData.map((singular) => {
    return {
      timestamp: epochToTimestamp(singular.timestampUtc),
      reading: (singular.meterReading / 1000).toFixed(4),
      consumption: (singular.meterConsumption / 1000).toFixed(4),
      temperature: singular.temperature,
      battery: singular.batteryLevel
    }

  });
}