import PropTypes from "prop-types";
import {Card} from "@mui/material";
import React, {useEffect, useState} from "react";
import {epochToTimestamp} from "util/UtilHelper";
import { columnDefs } from "layouts/analysis/kpi/components/LogAnomalyTable/tableUtil";
import {v4 as uuidv4} from "uuid";
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";
import { postUserPreference } from "util/APIHelper";
import { useMaterialUIController } from "context/md";
import MRTable from "components/MaterialReactTable";

const LogAnomalyTable = ({selectedMeters, endpointIntervals, data, isLoading}) => {
  const [anomalyData, setAnomalyData] = useState([]);
  const [id, setID] = useState(uuidv4());
  const [controller] = useMaterialUIController();
  const { user } = controller;
  const [columnState, setColumnState] = useState(() => {
    try {
      const savedState = JSON.parse(sessionStorage.getItem("kpi_log_anomaly_column_state"));
      return savedState || {};
    } catch (e) {
      return {};
    }
  });
  const columnStateCallback = async (item) => {
    const state = JSON.stringify(item);
    sessionStorage.setItem("kpi_log_anomaly_column_state", state);
    setColumnState(item);
    if (user) {
      await postUserPreference(user.email, [
        {
          type: "kpi_log_anomaly_column_state",
          value: state
        }
      ])
    }
  }

  useEffect(() => {
    if (!isLoading && data.length>0) {
      processData();
    }
  }, [isLoading]);

  const processData = () => {
    const rows = []
    data.forEach((date) => {
      date.data.forEach((data)=> {
        const expectedLogs = endpointIntervals.find((intervals) => intervals.endpoint === data.endpoint).expectedLogCount
        if (data.count < expectedLogs) {
          const temp = selectedMeters.find((mtr) => mtr.endpoint === data.endpoint)
          let meter = {
            ...temp
          }
          meter.date = date.date
          meter.log_count = `${data.count}/${expectedLogs}`

          meter.longitude = meter.item.longitude;
          meter.latitude = meter.item.latitude;
          meter.reading = (meter.item.meterReading/1000).toFixed(4)
          meter.timestamp = epochToTimestamp(meter.item.timestampUtc);

          meter.signal = (meter.item.rsrp)?+(meter.item.rsrp):-100;
          meter.signalStrength = meter.item.signalStrength;

          rows.push(meter)
        }
      })
    })

    setAnomalyData(rows)
    setID(uuidv4())
  }

  return (
    <Card>
      <MDBox p={3} lineHeight={1}>
        <MDTypography variant="h5" fontWeight="medium">
          MIU Log Anomaly
        </MDTypography>
        <MDTypography variant="button" color="text">
          MIUs with lower received log count than expected
        </MDTypography>
      </MDBox>
      <MRTable
        key={id}
        data={anomalyData}
        columns={columnDefs}
        isLoading={isLoading}
        onColumnStateChanged={columnStateCallback}
        initialColumnOrder={columnState.order}
        initialColumnPinning={columnState.pinning}
        initialColumnVisibility={columnState.visibility}
      />
    </Card>
  )
}



LogAnomalyTable.defaultProps = {
  isLoading: false
}

LogAnomalyTable.propTypes = {
  selectedMeters: PropTypes.array.isRequired,
  endpointIntervals: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool

}

export default LogAnomalyTable;