import MDBox from "components/MDBase/MDBox";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ContentCollapse from "components/ContentCollapse";
import Grid from "@mui/material/Grid2";
import TablePreference from "components/Cards/PreferenceCard";

function TableTab({handleDelete, preferences}) {
  const [groupings, setGroupings] = useState([]);
  const [openCollapse, setOpenCollapse] = useState(false);

  useEffect(() => {
    const obj = {
      "Meter": [],
      "Meter Details": [],
      "Group & Site": [],
      "Downlink": [],
      "Firmware": [],
      "KPI": [],
      "User": [],
    }

    preferences.forEach((item) => {
      let groupName = ""
      let tableName = ""
      switch (item.item) {
        case "downlink_queue_column_state": groupName = "Downlink"; tableName= "Queue"; break;
        case "downlink_status_column_state": groupName = "Downlink"; tableName= "Status"; break;
        case "firmware_file_column_state": groupName = "Firmware"; tableName= "File"; break;
        case "firmware_queue_column_state": groupName = "Firmware"; tableName= "Queue"; break;
        case "firmware_status_column_state": groupName = "Firmware"; tableName= "Status"; break;
        case "group_column_state": groupName = "Group & Site"; tableName= "Group"; break;
        case "kpi_log_anomaly_column_state": groupName = "KPI"; tableName= "Log Anomaly"; break;
        case "meter_anomaly_column_state": groupName = "Meter"; tableName= "Anomaly"; break;
        case "meter_details_alarm_column_state": groupName = "Meter Details"; tableName= "Alarm"; break;
        case "meter_details_anomaly_column_state": groupName = "Meter Details"; tableName= "Anomaly"; break;
        case "meter_details_downlink_column_state": groupName = "Meter Details"; tableName= "Downlink"; break;
        case "meter_details_firmware_column_state": groupName = "Meter Details"; tableName= "Firmware"; break;
        case "meter_details_reading_column_state": groupName = "Meter Details"; tableName= "Reading"; break;
        case "meter_details_status_column_state": groupName = "Meter Details"; tableName= "Status"; break;
        case "meter_status_column_state": groupName = "Meter"; tableName= "Status"; break;
        case "site_column_state": groupName = "Group & Site"; tableName= "Site"; break;
        case "user_column_state": groupName = "User"; tableName= "User"; break;
      }

      obj[groupName].push({tableName:tableName, id:item.item});
    });

    const list = []
    Object.keys(obj).forEach(key => {
      if (obj[key].length>0) {
        list.push({name:key, tables:obj[key]});
      }
    });

    setGroupings(list);
  }, [preferences]);

  const handlePrefs = (tables) => tables.map((value, index)=>(
    <TablePreference
      key={index}
      item={value.id}
      tableName={value.tableName}
      onDelete={handleDelete}
    />
  ))

  return (
    <MDBox>
      <Grid container spacing={2}>
        {
          groupings.map((value, index) => (
            <Grid key={index} size={{xs:12}}>
              <ContentCollapse
                title={`${value.name} Tables`}
                open={openCollapse === value.name}
                onClick={() => { (openCollapse === value.name)?setOpenCollapse(false):setOpenCollapse(value.name)}}
              >
                <MDBox sx={{padding:"0 1rem 1rem 1rem"}}>
                  {handlePrefs(value.tables)}
                </MDBox>
              </ContentCollapse>
            </Grid>
          ))
        }
      </Grid>
    </MDBox>
  )
}

TableTab.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  preferences: PropTypes.array.isRequired
}

export default TableTab;