import DashboardNavbar from "components/MDComponents/Navbars/DashboardNavbar";
import DashboardLayout from "components/MDComponents/LayoutContainers/DashboardLayout";
import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBase/MDBox";
import MDDatePicker from "components/MDBase/MDDatePicker";
import MDTypography from "components/MDBase/MDTypography";
import MDButton from "components/MDBase/MDButton";
import Icon from "@mui/material/Icon";
import {formatDate} from "util/UtilHelper";
import {Card} from "@mui/material";
import { getKPIAnalysis, getMeter, postUserPreference } from "util/APIHelper";
import { columnDefs, kpiMeterTableProcess } from "layouts/analysis/kpi/tableUtil";
import OverallKPIWidget from "layouts/analysis/kpi/components/OverallKPIWidget";
import OverallKPIGraph from "layouts/analysis/kpi/components/OverallKPIGraph";
import GroupsKPIWidgets from "layouts/analysis/kpi/components/GroupsKPIWidgets";
import GroupsKPIGraph from "layouts/analysis/kpi/components/GroupsKPIGraph";
import GroupsLogAnomalyGraph from "layouts/analysis/kpi/components/GroupsLogAnomalyGraph";
import LogAnomalyTable from "layouts/analysis/kpi/components/LogAnomalyTable";
import { useMaterialUIController } from "context/md";
import MRTable from "components/MaterialReactTable";
import SitesKPIGraph from "./components/SitesKPIGraph";
import SitesLogAnomalyGraph from "./components/SitesLogAnomalyGraph";

function AnalysisKPI() {
  const [controller] = useMaterialUIController();
  const { user } = controller;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const [meters, setMeters] = useState([]);
  const [selectedMeters, setSelectedMeters] = useState([]);
  const [passedSelectedMeters, setPassedSelectedMeters] = useState([]);
  const [columnState, setColumnState] = useState(() => {
    try {
      const savedState = JSON.parse(sessionStorage.getItem("kpi_meter_column_state"));
      return savedState || {};
    } catch (e) {
      return {};
    }
  });
  const columnStateCallback = async (item) => {
    const state = JSON.stringify(item);
    sessionStorage.setItem("kpi_meter_column_state", state);
    setColumnState(item);
    if (user) {
      await postUserPreference(user.email, [
        {
          type: "kpi_meter_column_state",
          value: state
        }
      ])
    }
  }

  useEffect(() => {
    getData()
  }, []);


  const handleStartDateChange = (date) => {setStartDate(date[0]);};
  const handleEndDateChange = (date) => {setEndDate(date[0]);};
  const checkDisable = () => {
    return !!((startDate && endDate && endDate < startDate) ||
      !startDate ||
      !endDate ||
      isLoading || selectedMeters.length === 0 );
  }

  const getData = async () => {
    setLoading(true);
    try {
      const response = await getMeter({stray:false});
      setMeters(kpiMeterTableProcess(response.content.meters));
    } catch (e) {
      console.error(e)
    }
    setLoading(false);
  }

  const [endpointIntervals, setEndpointIntervals] = useState([]);
  const [data, setData] = useState([]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      processGrouping();
      setPassedSelectedMeters(selectedMeters);
      const response = await getKPIAnalysis(formatDate(startDate), formatDate(endDate), selectedMeters.map((item)=>item.endpoint))
      setEndpointIntervals(response.content.endpoints)
      setData(response.content.data)
    } catch (e) {
      console.error(e)
    }
    setLoading(false);
  }
  const onRowSelectionChange = (table) => {
    setSelectedMeters(table.getSelectedRowModel().rows.map(it=>it.original));
  }

  const [groups, setGroups] = useState([]);

  const processGrouping = () => {
    const tempGroups = [];

    selectedMeters.forEach(meter => {
      if (!tempGroups.some(obj => obj.id === meter.group_id)) {
        tempGroups.push({id: meter.group_id, name: meter.group_name, sites: [{id: meter.site_id, name: meter.site_name}]});
      } else {
        const index = tempGroups.findIndex(obj => obj.id === meter.group_id);
        if (!tempGroups[index].sites.some(obj => obj.id === meter.site_id)) {
          tempGroups[index].sites.push({id: meter.site_id, name: meter.site_name});
        }
      }
    });
    setGroups(tempGroups);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar title="KPI" hideSites/>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <MRTable
              data={meters}
              columns={columnDefs}
              isLoading={isLoading}
              onRefresh={getData}
              enableRowSelection
              onRowSelectionChange={onRowSelectionChange}
              columnVisibilityCallback={columnStateCallback}
              initialColumnOrder={columnState.order}
              initialColumnPinning={columnState.pinning}
              initialColumnVisibility={columnState.visibility}
              customTopLeftToolbar={()=>(
                <MDBox display="flex" alignItems="center" ml={1.3} mb={1}>
                  <MDDatePicker
                    onChange={handleStartDateChange}
                    placeholder="Start Date"
                    options={{
                      dateFormat: "Y-m-d",
                      maxDate: new Date()
                    }}
                  />
                  <MDTypography variant="body2" fontWeight="light" color="text">
                    &nbsp; to &nbsp;
                  </MDTypography>
                  <MDDatePicker
                    onChange={handleEndDateChange}
                    placeholder="End Date"
                    options={{
                      dateFormat: "Y-m-d",
                      minDate: startDate,
                      maxDate: new Date()
                    }}
                  />
                  <MDBox ml={2}>
                    <MDButton disabled={checkDisable()} onClick={handleSubmit} variant="outlined" color="info">
                      <Icon>download</Icon>&nbsp; Generate
                    </MDButton>
                  </MDBox>
                </MDBox>
              )}
            />
          </Card>
        </Grid>

        <Grid item xs={3}>
          <OverallKPIWidget endpointIntervals={endpointIntervals} data={data} isLoading={isLoading} />
        </Grid>
        <Grid item xs={9}>
          <GroupsKPIWidgets
            data={data}
            selectedMeters={passedSelectedMeters}
            endpointIntervals={endpointIntervals}
            groups={groups}
          />
        </Grid>

        <Grid item xs={12}>
          <OverallKPIGraph endpointIntervals={endpointIntervals} data={data} isLoading={isLoading} />
        </Grid>

        <Grid item xs={6}>
          <GroupsKPIGraph
            selectedMeters={passedSelectedMeters}
            endpointIntervals={endpointIntervals}
            data={data}
            groups={groups}
            isLoading={isLoading} />
        </Grid>

        <Grid item xs={6}>
          <GroupsLogAnomalyGraph
            selectedMeters={passedSelectedMeters}
            endpointIntervals={endpointIntervals}
            data={data}
            groups={groups}
            isLoading={isLoading} />
        </Grid>

        <Grid item xs={6}>
          <SitesKPIGraph
            selectedMeters={passedSelectedMeters}
            endpointIntervals={endpointIntervals}
            data={data}
            groups={groups}
            isLoading={isLoading} />
        </Grid>

        <Grid item xs={6}>
          <SitesLogAnomalyGraph
            selectedMeters={passedSelectedMeters}
            endpointIntervals={endpointIntervals}
            data={data}
            groups={groups}
            isLoading={isLoading} />
        </Grid>

        <Grid item xs={12}>
          <LogAnomalyTable
            selectedMeters={passedSelectedMeters}
            endpointIntervals={endpointIntervals}
            data={data}
            isLoading={isLoading} />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default AnalysisKPI;