/* eslint-disable react/prop-types */
import { epochToTimestamp } from "util/UtilHelper";
import AnomalyMessageCell from "components/TableCells/AnomalyTable/AnomalyMessageCell";
import MeterSerialNumberCell from "components/TableCells/General/MeterSerialNumberCell";

export const columnDefs = [
  {
    header: "Meter S/N", accessorKey: "meter_sn",
    Cell: ({_, row }) =>
      <MeterSerialNumberCell
        meterSerialNumber={row.original.meter_sn}
        endpoint={row.original.endpoint}
      />
  },
  { header: "Anomaly Function",  accessorKey: "anomaly_function" },
  { header: "Anomaly Message", accessorKey: "anomaly_message",
    Cell: ({ _, row }) => <AnomalyMessageCell data={row.original} />
  },
  { header: "Timestamp", accessorKey: "timestamp" }
]

export const anomalyTableProcess = (rawData) => {
  return rawData.map((singular) => {
    return {
      endpoint: singular.endpoint,
      meter_sn: (singular.meterSerialNumber) ? singular.meterSerialNumber : singular.endpoint,
      anomaly_function: singular.anomalyFunction,
      anomaly_message: singular.anomalyMessage,
      timestamp: epochToTimestamp(singular.createdUtc),
    }
  });
}