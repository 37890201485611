import React, { useEffect, useState } from "react";
import { getMeter, postUserPreference } from "util/APIHelper";
import { columnDefs, meterTableProcess } from "layouts/meter/tableUtil";
import DashboardLayout from "components/MDComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/MDComponents/Navbars/DashboardNavbar";
import FirmwareQueueSubmit from "layouts/firmware/Queue/components/FirmwareQueueSubmit";
import { useMaterialUIController } from "context/md";
import MRTable from "components/MaterialReactTable";
import { useSignalEffect } from "@preact/signals-react";
import { selectedSites } from "signals/group_signals";

function FirmwareQueue() {
  const [meters, setMeters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMeters, setSelectedMeters] = useState([]);
  const [controller] = useMaterialUIController();
  const { user } = controller;

  const [columnState, setColumnState] = useState(() => {
    try {
      const savedState = JSON.parse(sessionStorage.getItem("firmware_queue_column_state"));
      return savedState || {};
    } catch (e) {
      return {};
    }
  });
  const columnStateCallback = async (item) => {
    const state = JSON.stringify(item);
    sessionStorage.setItem("firmware_queue_column_state", state);
    setColumnState(item);
    if (user) {
      await postUserPreference(user.email,[
        {
          type: "firmware_queue_column_state",
          value: state
        }
      ])
    }
  }

  const [isMounted, setIsMounted] = useState(false);
  useSignalEffect(()=>{
    selectedSites.value
    if (isMounted) {
      getData()
    }
  })

  useEffect(() => {
    getData()
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      if (selectedSites.value.length > 0) {
        const sites = selectedSites.value.map(e => e.id)
        const response = await getMeter({site:sites.join(",")});
        setMeters(meterTableProcess(response.content.meters));
      } else {
        setMeters([]);
      }
    } catch (e) {
      console.error(e)
    }
    setLoading(false)
    if (!isMounted) {
      setIsMounted(true)
    }
  }

  const onRowSelectionChange = (table) => {
    setSelectedMeters(table.getSelectedRowModel().rows.map(it=>it.original));
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MRTable
        data={meters}
        columns={columnDefs}
        isLoading={loading}
        onRefresh={getData}
        onColumnStateChanged={columnStateCallback}
        initialColumnOrder={columnState.order}
        initialColumnPinning={columnState.pinning}
        initialColumnVisibility={columnState.visibility}
        enableRowSelection
        onRowSelectionChange={onRowSelectionChange}
        customTopLeftToolbar={() => (
          <FirmwareQueueSubmit selectedMeters={selectedMeters} />
        )}
      />
    </DashboardLayout>
  );
}

export default FirmwareQueue;
