// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";

//SVG
import { ReactComponent as BackFlow } from "assets/images/alarms/backflow.svg"
import { ReactComponent as Battery } from "assets/images/alarms/battery.svg"
import { ReactComponent as Burst } from "assets/images/alarms/burst.svg"
import { ReactComponent as Leak } from "assets/images/alarms/leak.svg"
import { ReactComponent as Magnet } from "assets/images/alarms/magnet.svg"
import { ReactComponent as NoFlow } from "assets/images/alarms/noflow.svg"
import { ReactComponent as NRTBackFlow } from "assets/images/alarms/nrtbackflow.svg"
import { ReactComponent as Reboot } from "assets/images/alarms/reboot.svg"
import { ReactComponent as Signal } from "assets/images/alarms/signal.svg"
import { ReactComponent as Theft } from "assets/images/alarms/theft.svg"
import { ReactComponent as Tilt } from "assets/images/alarms/tilt.svg"
import { useTheme } from "@mui/material";

import palette from "assets/colors/index";
const {material} = palette;

export const getAlarmTitle = (alarm) => {
  switch (alarm) {
    case "backFlowDetection": return "Backflow";
    case "batteryLow": return "Battery Low";
    case "burstDetection": return "Burst";
    case "leakageDetection": return "Leak";
    case "magnetTamper": return "Magnet Tamper";
    case "noFlowDetection": return "No Flow";
    case "nonRealtimeBackFlowDetection": return "NR Backflow";
    case "rebootDetection": return "Reboot";
    case "faultySensor": return "Faulty Sensor";
    case "wireCutDetection": return "Wire Cut";
    case "tiltDetection": return "Tilt";
    default: return "";
  }
};

export const getAlarmColor = (alarm) => {
  switch (true) {
    case alarm === "backflow" || alarm === "backFlowDetection": return material.deep_purple["500"];
    case alarm === "battery_low" || alarm === "batteryLow": return material.amber["500"];
    case alarm === "burst" || alarm === "burstDetection": return material.red["500"];
    case alarm === "faulty_sensor" || alarm === "faultySensor": return material.grey["500"];
    case alarm === "leak" || alarm === "leakageDetection": return material.cyan["500"];
    case alarm === "magnet" || alarm === "magnetTamper": return material.blue_grey["500"];
    case alarm === "no_flow" || alarm === "noFlowDetection": return material.indigo["500"];
    case alarm === "non_realtime_backflow" || alarm === "nonRealtimeBackFlowDetection": return material.purple["500"];
    case alarm === "reboot" || alarm === "rebootDetection": return material.light_blue["500"];
    case alarm === "tilt" || alarm === "tiltDetection": return material.teal["500"];
    case alarm === "wire_cut" || alarm === "wireCutDetection": return material.deep_orange["500"];
    default: return material.yellow["500"];
  }
}

export const shortToLong = (alarm) => {
  switch (alarm) {
    case "backflow": return "backFlowDetection";
    case "battery_low": return "batteryLow";
    case "burst": return "burstDetection";
    case "faulty_sensor": return "faultySensor";
    case "leak": return "leakageDetection";
    case "magnet": return "magnetTamper";
    case "no_flow": return "noFlowDetection";
    case "non_realtime_backflow": return "nonRealtimeBackFlowDetection";
    case "reboot": return "rebootDetection";
    case "tilt": return "tiltDetection";
    case "wire_cut": return "wireCutDetection";
    default: return alarm;
  }
}
function AlarmIcon({ alarm, size, triggered, noTooltip, displayOnly=false }) {
  const getTitle = () => {
    switch (alarm) {
      case "backFlowDetection": return "Back Flow";
      case "batteryLow": return "Battery Low";
      case "burstDetection": return "Burst";
      case "leakageDetection": return "Leak";
      case "magnetTamper": return "Magnet Tamper";
      case "noFlowDetection": return "No Flow";
      case "nonRealtimeBackFlowDetection": return "Non-Realtime Back Flow";
      case "rebootDetection": return "Reboot";
      case "faultySensor": return "Faulty Sensor";
      case "wireCutDetection": return "Wire Cut";
      case "tiltDetection": return "Tilt";
      default: return "";
    }
  }

  const getSVG = () => {
    const theme = useTheme();
    const svgStyle = {
      width: size,
      height: size,
      fill: displayOnly ? theme.palette.text.main : (triggered) ? theme.palette.error.main : theme.palette.success.main,
    };

    switch (alarm) {
      case "backFlowDetection": return <BackFlow style={svgStyle} />;
      case "batteryLow": return <Battery style={svgStyle} />;
      case "burstDetection": return <Burst style={svgStyle} />;
      case "leakageDetection": return <Leak style={svgStyle} />;
      case "magnetTamper": return <Magnet style={svgStyle} />;
      case "noFlowDetection": return <NoFlow style={svgStyle} />;
      case "nonRealtimeBackFlowDetection": return <NRTBackFlow style={svgStyle} />;
      case "rebootDetection": return <Reboot style={svgStyle} />;
      case "faultySensor": return <Signal style={svgStyle} />;
      case "wireCutDetection": return <Theft style={svgStyle} />;
      case "tiltDetection": return <Tilt style={svgStyle} />;
    }
  }
  return displayOnly?(
    getSVG()
  ):(
    (
      <Tooltip title={noTooltip?"":getTitle()}>
        {getSVG()}
      </Tooltip>
    )
  );
}

// Setting default values for the props of ComplexStatisticsCard
AlarmIcon.defaultProps = {
  size: "2rem",
  triggered: false,
  noTooltip: false
};

// Typechecking props for the ComplexStatisticsCard
AlarmIcon.propTypes = {
  alarm: PropTypes.oneOf([
    "leakageDetection",
    "noFlowDetection",
    "burstDetection",
    "backFlowDetection",
    "batteryLow",
    "faultySensor",
    "wireCutDetection",
    "tiltDetection",
    "magnetTamper",
    "nonRealtimeBackFlowDetection",
    "rebootDetection",
  ]).isRequired,
  size: PropTypes.string,
  triggered: PropTypes.bool,
  noTooltip: PropTypes.bool,
  displayOnly: PropTypes.bool
};

export default AlarmIcon;
