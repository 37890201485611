import PropTypes from "prop-types";
import Carousel from "react-material-ui-carousel";
import MDBox from "components/MDBase/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DefaultLineChart from "components/MDComponents/Charts/LineCharts/DefaultLineChart";
import {useEffect, useMemo, useState} from "react";

import {
  epochTo12AM,
  getFormattedDateTime,
  getPast24Hours, getPast7Days
} from "util/UtilHelper";
import {
  getDetailsGraph,
  getReading
} from "util/APIHelper";

function GraphCarousel({endpoint}) {
  const [hourlyConsumptionData, setHourlyConsumptionData] = useState({});
  const [dailyConsumptionData, setDailyConsumptionData] = useState({});
  const [hourlyAnomalyData, setHourlyAnomalyData] = useState({});
  const [dailyAnomalyData, setDailyAnomalyData] = useState({});

  const [loading, setLoading] = useState({
    hourly_consumption: false,
    daily_consumption: false,
    hourly_anomaly: false,
    daily_anomaly: false
  });

  useEffect(() => {
    getData()
  }, []);

  const getData = async () => {
    setLoading(prevState => Object.keys(prevState).reduce((acc, key) => ({ ...acc, [key]: true }), {}))
    try {
      const _ = await getReading(endpoint, 1)
      const timestamp = _.content[0].createdUtc
      const latestTimestamp = epochTo12AM(timestamp)
      const todayTimestamp = getFormattedDateTime()

      const hourlyConsumptionResponse = await getDetailsGraph(endpoint, "consumption", todayTimestamp, "hourly")
      processData(hourlyConsumptionResponse.content, true, 'hourly', timestamp)
      setLoading(prevState => ({...prevState, hourly_consumption: false}))

      const dailyConsumptionResponse = await getDetailsGraph(endpoint, "consumption", latestTimestamp, "daily")
      processData(dailyConsumptionResponse.content, true, 'daily', timestamp)
      setLoading(prevState => ({...prevState, daily_consumption: false}))

      const hourlyAnomalyResponse = await getDetailsGraph(endpoint, "anomaly", todayTimestamp, "hourly")
      processData(hourlyAnomalyResponse.content, false, 'hourly', timestamp)
      setLoading(prevState => ({...prevState, hourly_anomaly: false}))

      const dailyAnomalyResponse = await getDetailsGraph(endpoint, "anomaly", latestTimestamp, "daily")
      processData(dailyAnomalyResponse.content, false, 'daily', timestamp)
      setLoading(prevState => ({...prevState, daily_anomaly: false}))
    } catch (e) {

    }
  }

  const processData = (data, consumption, interval, timestamp) => {
    let datetime_list;
    if (interval === 'hourly') {
      datetime_list = getPast24Hours(getFormattedDateTime());
    } else if (interval === 'daily') {
      datetime_list = getPast7Days(epochTo12AM(timestamp));
    } else {
      throw new Error('Invalid interval specified. Please specify either "hourly" or "daily".');
    }

    const processedData = {
      labels: datetime_list.map(item => interval === 'hourly' ? item.toLocaleString() : item.toLocaleDateString()),
      x_axis: datetime_list.map(item => interval === 'hourly' ? item.toLocaleString().split(', ')[1].replace(':00:00', ':00') : item.toLocaleDateString()),
      datasets: [
        {
          label: consumption ? "Consumption m³" : "Anomaly",
          color: "info",
          data: data.map(item => consumption ? (item / 1000) : item),
        },
      ],
    };

    if (consumption) {
      if (interval === 'hourly') {
        setHourlyConsumptionData(processedData);
      } else if (interval === 'daily') {
        setDailyConsumptionData(processedData);
      }
    } else {
      if (interval === 'hourly') {
        setHourlyAnomalyData(processedData);
      } else if (interval === 'daily') {
        setDailyAnomalyData(processedData);
      }
    }
  };


  const getChart = (consumption, interval = "daily") => {
    const data = (consumption && interval === 'hourly') ? hourlyConsumptionData :
      (consumption && interval === 'daily') ? dailyConsumptionData :
        (!consumption && interval === 'hourly') ? hourlyAnomalyData :
          (!consumption && interval === 'daily') ? dailyAnomalyData : null;

    const title = `${(interval==='daily')?'Daily':'Hourly'} ${(consumption)?'consumption':'anomaly'}`

    let containAnomaly = false;
    let count = 0;
    if (data.datasets !== undefined) {
      containAnomaly = (!consumption && data.datasets[0].data.reduce((total, currentValue) => total + currentValue, 0) > 0);
      count = data.datasets[0].data.reduce((acc, curr) => acc + curr, 0);
    }

    const description = `${(consumption)?count.toFixed(4):count}${(consumption)?' m³':''} ${(consumption)?'consumption for':'anomaly in'} the past ${(interval === 'hourly')?'24 hours':'7 days'}.`;
    return (
      <Grid item xs={12} lg={6}>
        <Card>
          <DefaultLineChart
            icon={{
              color:(containAnomaly)?"error":"info",
              component: (consumption)?"show_chart":"warning"
            }}
            title={title}
            height="20rem"
            description={description}
            chart={data}
            alert={consumption}
            loading={loading[`${interval==="daily"?"daily":"hourly"}_${consumption?"consumption":"anomaly"}`]}
          />
        </Card>
      </Grid>
    );
  };


  return (
    <MDBox>
      <Carousel autoPlay={false} navButtonsAlwaysInvisible >
        <MDBox pt={2}>
          <Grid spacing={3} container>
            {useMemo(()=>getChart(true, "hourly"),[hourlyConsumptionData])}
            {useMemo(()=>getChart(true),[dailyConsumptionData])}
          </Grid>
        </MDBox>

        <MDBox pt={2}>
          <Grid spacing={3} container>
            {useMemo(()=>getChart(false, "hourly"),[hourlyAnomalyData])}
            {useMemo(()=>getChart(false),[dailyAnomalyData])}
          </Grid>
        </MDBox>
      </Carousel>
    </MDBox>
  );
}

GraphCarousel.propTypes = {
  endpoint: PropTypes.string.isRequired,
}

export default GraphCarousel;