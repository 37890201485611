/* eslint-disable react/prop-types */
import { epochToTimestamp } from "util/UtilHelper";
import MeterCoordinateCell from "components/TableCells/MeterTable/MeterCoordinateCell";
import MeterStatusCell from "components/TableCells/MeterTable/MeterStatusCell";
import MeterAlarmCell from "components/TableCells/MeterTable/MeterAlarmCell";
import MeterSignalCell from "components/TableCells/MeterTable/MeterSignalCell";
import Alarm from "model/Alarm";
import MeterSerialNumberCell from "components/TableCells/General/MeterSerialNumberCell";

export const columnDefs = [
  { header: "Endpoint", accessorKey: "endpoint", hide: true },
  { header: "Meter S/N", accessorKey: "meter_sn",
    Cell: ({_, row}) =>
      <MeterSerialNumberCell
        meterSerialNumber={row.original.meter_sn}
        endpoint={row.original.endpoint}
        unClickable={row.original.un_clickable}
      />
  },
  { header: "MIU S/N", accessorKey: "miu_sn", hide: true },
  { header: "Registration Date", accessorKey: "registration_date", hide: true },
  { header: "Last Update", accessorKey: "last_update", hide: true },
  { header: "Coordinate", accessorKey: "coordinate", hide: true,
    Cell: ({ _, row }) =>
      <MeterCoordinateCell data={{
        latitude: row.original.latitude,
        longitude: row.original.longitude
      }} />
  },
  { header: "Status", accessorKey: "status",
    Cell: ({ cell }) => <MeterStatusCell data={cell.getValue()} />},
  { header: "Group", accessorKey: "group_name", hide: true },
  { header: "Site", accessorKey: "site_name", hide: true },
  { header: "Custom 1", accessorKey: "custom_1" },
  { header: "Custom 2", accessorKey: "custom_2" },
  { header: "Custom 3", accessorKey: "custom_3" },
  { header: "Custom 4", accessorKey: "custom_4", hide: true, },
  { header: "Custom 5", accessorKey: "custom_5", hide: true, },
  { header: "Reading (m³)", accessorKey: "reading" },
  { header: "Timestamp", accessorKey: "timestamp" },
  { header: "Alarms", accessorKey: "alarms",
    Cell: ({ _, row }) => <MeterAlarmCell data={row.original.alarmObject} />, },
  { header: "Signal", accessorKey: "signal",
    Cell: ({ _, row }) => <MeterSignalCell data={row.original} />, },
  { header: "Temperature °C", accessorKey: "temperature", hide: true, },
  { header: "Battery Level (%)", accessorKey: "batteryLevel", hide: true, },
  { header: "Battery Voltage (V)", accessorKey: "batteryVoltage", hide: true, },
]

export const meterTableProcess = (rawData) => {
  return rawData.map((singular) => {
    const alarm = new Alarm(singular);
    return {
      endpoint: singular.endpoint,
      meter_sn: (singular.meterSerialNumber) ? singular.meterSerialNumber : singular.endpoint,
      miu_sn: singular.miuSerialNumber,
      registration_date: epochToTimestamp(singular.createdUtc),
      last_update: epochToTimestamp(singular.modifiedUtc),
      coordinate: `${singular.latitude}, ${singular.longitude}`,
      longitude: singular.longitude,
      latitude: singular.latitude,
      status: singular.registrationStatus,
      custom_1: singular.custom1,
      custom_2: singular.custom2,
      custom_3: singular.custom3,
      custom_4: singular.custom4,
      custom_5: singular.custom5,
      temperature: singular.temperature,
      reading: (singular.meterReading / 1000).toFixed(4),
      timestamp: epochToTimestamp(singular.timestampUtc),
      alarms: alarm.getTotalSeverityLevel(),
      alarmObject: alarm,
      signal: (singular.rsrp) ? +(singular.rsrp) : -100,
      signalStrength: singular.signalStrength,
      configuration: "",
      batteryLevel: singular.batteryLevel,
      batteryVoltage: singular.batteryVoltage,
      item: singular,
      stray: (singular.groupId === undefined),
      uploaded: (singular.groupId !== undefined),
      noAction: (singular.createdUtc !== 0 && singular.groupId === undefined),
      registered: (singular.createdUtc !== 0),
      un_clickable: (singular.createdUtc === 0 && singular.groupId !== undefined),
      group_id: singular.groupId ?? -1,
      group_name: singular.groupName ?? "",
      site_id: singular.siteId ?? -1,
      site_name: singular.siteName ?? ""
    }
  });
}