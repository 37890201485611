import Card from "@mui/material/Card";
import { Modal } from "@mui/material";
import Grid from "@mui/material/Grid2";
import PropTypes from "prop-types";
import MDTypography from "components/MDBase/MDTypography";
import Divider from "@mui/material/Divider";
import MDButton from "components/MDBase/MDButton";
import MDBox from "components/MDBase/MDBox";
import { columnDefs } from "./tableUtil";
import MRTable from "components/MaterialReactTable";
import React, { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import MDInput from "components/MDBase/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import { getMeter, postAssignMeter } from "util/APIHelper";
import { meterTableProcess } from "layouts/meter/tableUtil";
import { muiPaginationProps } from "components/MaterialReactTable/style";
import { hexToRGBA } from "util/UtilHelper";
import { getMRT_SelectAllHandler } from "material-react-table";
import { setSBContent, setSBOpen, useSBController } from "context/snackbar";
import { allSites } from "signals/group_signals";

function SiteMeterModal({open, handleClose, siteInfo}){
  const style = {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 2rem)',
    maxWidth: "calc(100vw - 4rem)",
    maxHeight: "calc(100vh - 4rem)",
    width: "100%",
    outline: "none",
    boxShadow: 24,
    padding: "2rem 1rem 1rem 1rem",
  };

  const [isLoading, setLoading] = useState(false);
  const [originalMeters, setOriginalMeters] = useState([]);
  const [leftTable, setLeftTable] = useState([]);
  const [rightTable, setRightTable] = useState([]);

  const [leftSelected, setLeftSelected] = useState([]);
  const [rightSelected, setRightSelected] = useState([]);

  const [selectedSite, setSelectedSite] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (open) {
      getData()
    } else {
      setOriginalMeters([]);
      setLeftTable([]);
      setRightTable([]);
      setLeftSelected([]);
      setRightSelected([]);
      setSelectedSite(null);
    }
  }, [open]);

  const getData = async () => {
    setLoading(true);
    const response = await getMeter({
      stray: false,
    });
    const processed = meterTableProcess(response.content.meters)
    setLeftTable(processed.filter(e=>e.site_id === siteInfo.id));
    setRightTable(processed.filter(e=>e.site_id !== siteInfo.id));
    setOriginalMeters(processed);
    setLoading(false);
  }

  const leftTableOnSelectionChange = (table) => {
    setLeftSelected(table.getSelectedRowModel().rows.map(it=>it.original));
  }
  const rightTableOnSelectionChange = (table) => {
    setRightSelected(table.getSelectedRowModel().rows.map(it=>it.original));
  }

  const addSelected = (table, event)=>{
    const filteredList = rightTable.filter(
      e => !rightSelected.some(e2 => e.endpoint === e2.endpoint)
    )
    setRightTable(filteredList);
    const modified = rightSelected.map(e=>({
      ...e,
      site_id: siteInfo.id
    }));
    setLeftTable((prevState) => [...prevState, ...modified]);
    setRightSelected([]);
    getMRT_SelectAllHandler({ table })(event, false, true)
  }

  const removeSelected = (table, event) => {
    const filteredList = leftTable.filter(
      e => !leftSelected.some(e2 => e.endpoint === e2.endpoint)
    )
    setLeftTable(filteredList);
    const modified = leftSelected.map(e=>({
      ...e,
      site_id: selectedSite.id
    }));
    setRightTable((prevState) => [...prevState, ...modified]);
    setLeftSelected([]);
    getMRT_SelectAllHandler({ table })(event, false, true)
  }

  const handleSubmit = async () => {
    if (isSubmitting) {
      setLoading(true);
      try {
        const modifiedMeters = [];
        const combinedList = [...leftTable, ...rightTable];

        combinedList.forEach((item) => {
          const originalItem = originalMeters.find(e => e.endpoint === item.endpoint);
          if (originalItem) {
            if (originalItem.site_id !== item.site_id) {
              modifiedMeters.push(item)
            }
          }
        });

        const groupedBySiteId = modifiedMeters.reduce((acc, obj) => {
          const siteId = obj.site_id.toString();
          if (!acc[siteId]) {
            acc[siteId] = [];
          }
          acc[siteId].push(obj.endpoint);
          return acc;
        }, {});

        const sites = []

        Object.entries(groupedBySiteId).forEach(([key, value]) => {
          sites.push({siteId: parseInt(key), endpoints: value})
        });

        if (sites.length === 0) {
          handleSB({
            color: "error",
            icon: "warning",
            title: "Error",
            content: "No changes made"
          })
        } else {
          const response = await postAssignMeter(sites);
          if (response.success) {
            handleSB({
              color: "success",
              icon: "check",
              title: "Success",
              content: response.content
            })
            setOriginalMeters(combinedList);
          } else {
            handleSB({
              color: "error",
              icon: "warning",
              title: "Error",
              content: response.message
            })
          }
        }
      } catch (e) {
        console.error(e)
        handleSB({
          color: "error",
          icon: "warning",
          title: "Error",
          content: e
        })
      }
      setLoading(false);
      setSubmitting(false);
    } else {
      setSubmitting(true);
    }
  }
  const [sb_controller, sb_dispatch] = useSBController();
  const {content} = sb_controller;
  const handleSB = (newState) => {
    setSBContent(sb_dispatch, {
      ...content,
      ...newState
    })
    setSBOpen(sb_dispatch,true)
  }

  useEffect(() => {
    if (isSubmitting) {
      setSubmitting(false);
    }
  }, [leftTable, rightTable, leftSelected, rightSelected, selectedSite]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <MDTypography sx={{display:"flex", justifyContent:"center"}} id="modal-modal-title" variant="h4" component="h2">
          Add / Remove Meter
        </MDTypography>
        <Divider />
        <Grid container spacing={1}>
          <Grid size={{xs: 6}}>
            <MRTable
              noExcel
              compact
              data={leftTable}
              columns={columnDefs}
              isLoading={isLoading}
              enableRowSelection
              onRowSelectionChange={leftTableOnSelectionChange}
              muiTablePaperProps={{
                elevation: 0,
              }}
              muiTableContainerProps={{ sx: { maxHeight: 'calc(100vh - 30rem)' } }}
              muiPaginationProps={{
                ...muiPaginationProps,
                showRowsPerPage: false,
              }}
              mrtTheme={(theme) => ({
                baseBackgroundColor: theme.palette.background.default,
                draggingBorderColor: theme.palette.secondary.main,
                menuBackgroundColor: theme.palette.background.sidenav,
                selectedRowBackgroundColor: hexToRGBA(theme.palette.secondary.main, 0.2),
                transition: "all 350ms ease-out",
              })}
              customTopRightToolbar={(table)=> (
                <MDBox sx={{margin:"0 0.5rem 1rem 0"}} >
                  <MDButton disabled={(leftSelected.length === 0) || !selectedSite} onClick={(event) => {removeSelected(table, event)}} variant="outlined" color="info">
                     Remove &nbsp;<Icon>east</Icon>
                  </MDButton>
                </MDBox>
              )}
              customTopLeftToolbar={()=>(
                <MDTypography sx={{margin:"0 0 1.5rem 0.5rem"}} variant="h4">
                  {siteInfo.name}
                </MDTypography>
              )}
            />
          </Grid>
          <Grid size={{xs: 6}}>
            <MRTable
              noExcel
              compact
              data={selectedSite?rightTable.filter(e=>e.site_id === selectedSite.id):[]}
              columns={columnDefs}
              isLoading={isLoading}
              enableRowSelection
              onRowSelectionChange={rightTableOnSelectionChange}
              muiTablePaperProps={{
                elevation: 0,
              }}
              muiTableContainerProps={{ sx: { maxHeight: 'calc(100vh - 30rem)' } }}
              muiPaginationProps={{
                ...muiPaginationProps,
                showRowsPerPage: false,
              }}
              mrtTheme={(theme) => ({
                baseBackgroundColor: theme.palette.background.default,
                draggingBorderColor: theme.palette.secondary.main,
                menuBackgroundColor: theme.palette.background.sidenav,
                selectedRowBackgroundColor: hexToRGBA(theme.palette.secondary.main, 0.2),
                transition: "all 350ms ease-out",
              })}
              customTopRightToolbar={() => (
                <Autocomplete
                  size="small"
                  sx={{ margin:"0 0.5rem 1rem 0.5rem", minWidth: "11rem", }}
                  groupBy={(option) => option.groupDescription}
                  options={allSites.value.filter(e=>(e.id !== siteInfo.id && e.id !== -1))}
                  onChange={(event, value) => {
                    setSelectedSite(value);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option) => `${option.description} (${rightTable.filter(e=>e.site_id === option.id).length})`}
                  renderInput={(params) => <MDInput label="Sites" {...params} />}
                />
              )}
              customTopLeftToolbar={(table)=> (
                <MDBox sx={{margin:"0 0.5rem 1rem 0.5rem"}} >
                  <MDButton disabled={rightSelected.length === 0} onClick={(event) => {addSelected(table,event)}} variant="outlined" color="info">
                    <Icon>west</Icon>&nbsp; Add
                  </MDButton>
                </MDBox>
              )}
            />
          </Grid>
        </Grid>
        <MDBox mt={3} sx={{display:"flex", justifyContent:"flex-end"}}>
          <MDButton variant="outlined" color="white" sx={{marginRight:"1rem"}} onClick={()=>{
            if (isSubmitting) {
              setSubmitting(false);
            } else {
              handleClose()
            }
          }}>Cancel</MDButton>
          <MDButton variant="gradient" color={isSubmitting?"success":"info"} onClick={handleSubmit}>{isSubmitting?"Confirm":"Submit"}</MDButton>
        </MDBox>
      </Card>
    </Modal>
  )
}

SiteMeterModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  siteInfo: PropTypes.object.isRequired
}
export default SiteMeterModal
