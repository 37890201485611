import React, {useEffect, useState} from 'react';
import { Popup } from 'react-leaflet';
import PropTypes from "prop-types";
import {Skeleton} from "@mui/material";
import {getAlarm, getReading} from "util/APIHelper";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";
import Tooltip from "@mui/material/Tooltip";
import {useNavigate} from "react-router-dom";
import WebSocketService from "model/WebSocketService";
import {epochToTimestamp} from "util/UtilHelper";
import Alarm from "model/Alarm";
import useCooldown from "util/hooks/useCooldown";
import AlarmIcon from "components/AlarmIcon";

const InfoWindow = ({ content, isOpen }) => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [reading, setReading] = useState([]);
  const [alarm, setAlarm] = useState([]);

  useEffect(() => {
    const handleSignal = (data) => {
      try {
        const message = JSON.parse(data.data)
        if (message.type === "DeviceTable" && message.action === "Refresh" && document.getElementById(content.info)) {
          if (message.content.endpoint === content.info) {
            throttledGetData()
          }
        }
      } catch (e) {}
    };

    WebSocketService.addMessageListener(handleSignal);
    return () => {
      WebSocketService.removeMessageListener(handleSignal);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      getData()
    }
  }, [isOpen]);

  const getData = async () => {
    setLoading(true)
    try {
      const readingResponse = await getReading(content.info, 1);
      const alarmResponse = await getAlarm(content.info, 1);

      if (readingResponse.success) {
        setReading(readingResponse.content)
      } else {
        setReading([])
      }

      if (alarmResponse.success) {
        setAlarm(alarmResponse.content)
      } else {
        setAlarm([])
      }
    } catch (e) {
      console.error(e)
    }
    setLoading(false)
  }
  const throttledGetData = useCooldown(getData, 350);

  const getReadingValue = () => {
    if (isLoading) {
      return <Skeleton height="1rem" width="100%" />
    } else {
      if (reading?.length>0) {
        return (reading[0].meterReading/1000)
      }
      return "-"
    }
  }

  const getReadingTimestamp = () => {
    if (isLoading) {
      return <Skeleton height="1rem" width="100%" />
    } else {
      if (reading?.length>0) {
        return epochToTimestamp(reading[0].timestampUtc)
      }
      return "-"
    }
  }

  const getAlarms = () => {
    if (isLoading) {
      return <Skeleton height="1rem" width="100%" />
    } else {
      if (alarm?.length>0) {
        const alarmObj = new Alarm(alarm[0])
        if (alarmObj.getNumberOfActiveAlarms() > 0) {
          const alarms = alarmObj.getActiveAlarms();
          return (
            <MDBox>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <MDTypography
                    variant="body"
                    fontWeight="bold"
                    color="inherit"
                  >
                    Alarms:&nbsp;
                  </MDTypography>
                  <MDTypography
                    color="info"
                    variant="body"
                    onClick={()=>{navigate(`/meter/details?endpoint=${content.info}&tab=alarm`)}}
                    sx={{cursor: "pointer",}}
                  >
                    View Alarms
                  </MDTypography>
                </Grid>
                <Grid item xs={12}>
                  <MDBox sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                    {alarms.includes("leakageDetection") && <AlarmIcon alarm="leakageDetection" size="1.5rem" triggered />}
                    {alarms.includes("noFlowDetection") && <AlarmIcon alarm="noFlowDetection" size="1.5rem" triggered />}
                    {alarms.includes("burstDetection") && <AlarmIcon alarm="burstDetection" size="1.5rem" triggered />}
                    {alarms.includes("backFlowDetection") && <AlarmIcon alarm="backFlowDetection" size="1.5rem" triggered />}
                    {alarms.includes("batteryLow") && <AlarmIcon alarm="batteryLow" size="1.5rem" triggered />}
                    {alarms.includes("faultySensor") && <AlarmIcon alarm="faultySensor" size="1.5rem" triggered />}
                    {alarms.includes("wireCutDetection") && <AlarmIcon alarm="wireCutDetection" size="1.5rem" triggered />}
                    {alarms.includes("tiltDetection") && <AlarmIcon alarm="tiltDetection" size="1.5rem" triggered />}
                    {alarms.includes("magnetTamper") && <AlarmIcon alarm="magnetTamper" size="1.5rem" triggered />}
                    {alarms.includes("nonRealtimeBackFlowDetection") && <AlarmIcon alarm="nonRealtimeBackFlowDetection" size="1.5rem" triggered />}
                    {alarms.includes("rebootDetection") && <AlarmIcon alarm="rebootDetection" size="1.5rem" triggered />}
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          )
        }
      }
      return <>
        <MDTypography
          variant="body"
          fontWeight="bold"
          color="inherit"
        >
          Alarms:&nbsp;
        </MDTypography>
        No Active Alarms
      </>
    }
  }

  return (
    <Popup>
      <Grid id={content.info} container spacing={1}>
        <Grid item xs={12}>
          <Tooltip title={content.info}>
            <MDTypography
              color="info"
              variant="body"
              onClick={()=>{navigate(`/meter/details?endpoint=${content.info}`)}}
              sx={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'pre-line',
                WebkitLineClamp: 1, // Set the number of lines to display
                cursor: "pointer",
                textAlign: "center", // Center align the content
                borderBottom: "1px solid #ccc", // Add border line below the text
                paddingBottom: "0.5rem"
              }}
            >
              {content.item.meterSerialNumber}
            </MDTypography>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <MDBox sx={{minWidth:"12rem"}}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <MDBox display="flex">
                  {
                    (isLoading) ? (
                      <Skeleton height="1.25rem" width="100%" />
                    ):(
                      <>
                        <MDTypography
                          variant="body"
                          fontWeight="bold"
                          color="inherit"
                        >
                          Reading (m³):&nbsp;
                        </MDTypography>
                        {getReadingValue()}
                      </>
                    )
                  }
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                {
                  (isLoading) ? (
                    <Skeleton height="1.25rem" width="100%" />
                  ):getReadingTimestamp()
                }
                </Grid>
              <Grid item xs={12}>
                <MDBox display="flex" mt={1}>
                  {
                    (isLoading) ? (
                      <Skeleton height="1.25rem" width="100%" />
                    ):(
                      <>
                        {getAlarms()}
                      </>
                    )
                  }
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
      </Grid>
    </Popup>
  );
};

InfoWindow.propTypes = {
  content: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired
}

export default InfoWindow;
