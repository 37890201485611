import React, { useEffect, useMemo, useState } from "react";
import {Card} from "@mui/material";
import PropTypes from "prop-types";
import VerticalBarChart from "components/MDComponents/Charts/BarCharts/VerticalBarChart";
import MDBox from "components/MDBase/MDBox";
import MDButton from "components/MDBase/MDButton";
import MDInput from "components/MDBase/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import { checkStatus } from "../../../../../util/UtilHelper";

const SitesLogAnomalyGraph = ({selectedMeters, endpointIntervals, data, groups, isLoading}) => {
  const [chartData, setChartData] = useState({});
  const [sites, setSites] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [stacked, setStacked] = useState(true);

  useEffect(() => {
    if (!isLoading && data.length>0) {
      if (groups.length>0) {
        const siteList = groups.map(group => group.sites.map(site => ({id: site.id, name: site.name, group_id: group.id, group_name: group.name}))).flat()
        setSelectedSites(siteList.slice(0,10));
        setSites(siteList);
        processData(siteList, siteList.slice(0,10));
      }
    }
  }, [isLoading]);

  const processData = (allSites = null, items = null) => {
    let colors = {};
    try {
      if (JSON.parse(sessionStorage.getItem("group_site_color"))) {
        colors = JSON.parse(sessionStorage.getItem("group_site_color"));
      }
    } catch (e) {
      console.error(e)
    }
    const datasets = [];

    (allSites??sites).forEach((site) => {
      if (((items)??selectedSites).find((obj) => obj.id === site.id)) {
        const list = selectedMeters.filter((meter) => meter.site_id === site.id ).map((_)=>_.endpoint)
        const filteredData = data.map((dates) => {
          return {
            ...dates,
            data: dates.data.filter((date_data) => (list.includes(date_data.endpoint)))
          }
        });
        const filteredEndpointIntervals = endpointIntervals.filter((intervals) => list.includes(intervals.endpoint))
        const data_list = []

        filteredData.forEach((item)=> {
          let i = 0
          item.data.forEach((item2) => {
            if (item2.count<(filteredEndpointIntervals.find((_)=>_.endpoint === item2.endpoint).expectedLogCount)) {
              i++;
            }
          });
          data_list.push(i)
        });

        let c = (data_list.reduce((acc, cur) => acc + cur, 0)>0)?"warning":"info";

        if (colors.sites) {
          if (colors.sites.length > 0) {
            const index = colors.sites.findIndex((obj) => obj.id === site.id);
            if (index > -1) {
              c = colors.sites[index].color
            }
          }
        }

        const subData = {
          label: site.name,
          color: c,
          data: data_list
        }

        datasets.push(subData)
      }
    });

    const processedData = {
      labels: data.map((item) => item.date),
      datasets: datasets
    }
    setChartData(processedData)
  }

  const onChange = (item) => {
    setSelectedSites(item)
    processData(item);
  }

  return (
    <Card>
      {
        useMemo(()=>(
          <VerticalBarChart
            icon={{
              color:"info",
              component: "leaderboard"
            }}
            title="Sites Log Anomaly"
            height="30rem"
            description=" "
            chart={chartData}
            loading={isLoading}
            stacked={stacked}
            legend
            extraAction={
              <>
                <MDBox sx={{ marginRight: "1rem", marginTop:"-0.375rem"}}>
                  <MDButton
                    color="secondary"
                    size="small"
                    variant="outlined"
                    onClick={()=>{setStacked(!stacked)}}
                  >
                    {stacked?"Stacked":"Grouped"}
                  </MDButton>
                </MDBox>

                <Autocomplete
                  sx={{ paddingLeft: "1rem", minWidth: "11rem", marginRight:"1rem", marginBottom:"1rem", maxWidth:"20rem"}}
                  multiple
                  limitTags={1}
                  value={selectedSites}
                  options={groups}
                  onChange={(event, value) => {
                    onChange(value);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <MDInput  label="Sites" {...params} />}
                />
              </>
            }
          />
        ), [chartData, isLoading, selectedSites, stacked])
      }
    </Card>
  );
}

SitesLogAnomalyGraph.defaultProps = {
  isLoading: false
}

SitesLogAnomalyGraph.propTypes = {
  selectedMeters: PropTypes.array.isRequired,
  endpointIntervals: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  isLoading: PropTypes.bool

}

export default SitesLogAnomalyGraph;