/* eslint-disable react/prop-types */
import { epochToTimestamp } from "util/UtilHelper";
import MeterSerialNumberCell from "components/TableCells/General/MeterSerialNumberCell";
import { getFirmwareStatusColor } from "util/LookupUtil";
import MDTypography from "components/MDBase/MDTypography";

export const columnDefs = [
  { header: "Meter S/N", accessorKey: "meter_sn",
    Cell: ({_, row}) =>
      <MeterSerialNumberCell
        meterSerialNumber={row.original.meter_sn}
        endpoint={row.original.endpoint}
      />
  },
  { header: "File Name", accessorKey: "file_name" },
  { header: "Created Timestamp", accessorKey: "created_timestamp" },
  { header: "Completed Timestamp", accessorKey: "completed_timestamp" },
  { header: "Status", accessorKey: "status",
    Cell: ({ cell, row }) =>
      <MDTypography
        variant="body"
        color={getFirmwareStatusColor(row.original.item)}
      >
        {cell.getValue()}
      </MDTypography>
  }
]

export const firmwareTableProcess = (rawData) => {
  return rawData.map((singular) => {
    return {
      item: singular,
      endpoint: singular.endpoint,
      meter_sn: (singular.meterSerialNumber) ? singular.meterSerialNumber : singular.endpoint,
      file_name: singular.fileName,
      created_timestamp: epochToTimestamp(singular.createdUtc),
      completed_timestamp: epochToTimestamp(singular.modifiedUtc),
      status: singular.description
    }

  });
}