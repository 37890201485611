import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import SimpleGraph from "components/Charts/SimpleGraph";
import {getDashboardAnomalyGraph} from "util/APIHelper";
import {capitalizeFirstLetter, getPast7Days, getToday} from "util/UtilHelper";
import {Card} from "@mui/material";
import WebSocketService from "model/WebSocketService";
import {v4 as uuidv4} from "uuid";
import useCooldown from "util/hooks/useCooldown";
import { useSignalEffect } from "@preact/signals-react";
import { selectedSites } from "signals/group_signals";

function DashboardAnomalyGraphWidget({type}) {
  const [key, setKey] = useState(uuidv4())
  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const handleSignal = (data) => {
      try {
        const message = JSON.parse(data.data)
        if (message.type === "AnomalyTable" && message.action === "Refresh") {
          throttledGetData()
        }
      } catch (e) {}
    };

    WebSocketService.addMessageListener(handleSignal);
    return () => {
      WebSocketService.removeMessageListener(handleSignal);
    };
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const [isMounted, setIsMounted] = useState(false);
  useSignalEffect(()=>{
    selectedSites.value
    if (isMounted) {
      throttledGetData()
    }
  });

  const getData = async () => {
    try {
      let defaultAlarmData = {
        labels: [],
        datasets: [
          {
            label: "",
            color: "info",
            data: [],
          },
        ],
      }

      if (selectedSites.value.length>0) {
        const sites = selectedSites.value.map(e => e.id);
        const response = await getDashboardAnomalyGraph(type, getToday(),sites.join(","), sites.includes(-1))

        let color = "info"
        if (response.content.reduce((ac, cv) => ac + cv, 0)>0) { color = "warning"}
        if (response.content.reduce((ac, cv) => ac + cv, 0)>=10) { color = "error"}

        defaultAlarmData = {
          labels: getPast7Days(getToday()).map((item)=>item.toLocaleDateString()),
          datasets: [
            {
              label: capitalizeFirstLetter(type),
              color: color,
              data: response.content,
            },
          ],
        }
      }

      setData(defaultAlarmData)
    } catch (e) {
      console.error(e)
    }
    setKey(uuidv4())
    if (!isMounted) {
      setIsMounted(true)
    }
  }
  const throttledGetData = useCooldown(getData, 1000);

  const getTitle = () => {
    switch (type) {
      case 'offline': return "Offline Anomaly";
      case 'alarm': return "Alarm Anomaly";
      case 'reading': return "Reading Anomaly"
    }
  }

  const getLink = () => {
    switch (type) {
      case 'offline': return "/meter/anomaly?type=offline";
      case 'alarm': return "/meter/anomaly?type=alarm";
      case 'reading': return "/meter/anomaly?type=reading"
    }
  }

  return (
    <Card>
      <SimpleGraph
        key={key}
        title={getTitle()}
        count={data.datasets[0]?.data.reduce((ac, cv) => ac + cv, 0)??0}
        percentage={{ color: "success", label: "" }}
        chart={data}
        link={getLink()}
      />
    </Card>
  )
}

DashboardAnomalyGraphWidget.propTypes = {
  type: PropTypes.oneOf([
    "offline",
    "alarm",
    "reading"
  ])
}

export default DashboardAnomalyGraphWidget;